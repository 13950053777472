import { utils } from 'ethers'
import React, { useState } from 'react'

import dayjs from 'dayjs'
import { useFormContext } from 'react-hook-form'
import { useAccount } from 'wagmi'

import ScheduledBondModal from './scheduledBondModal'
import { BondSelector } from '../../ProductCreate/selectors/CollateralTokenSelector'

import { TokenItem } from '@/components/auction/Claimer'
import { TokenPill } from '@/components/bond/BondAction'
import TooltipElement from '@/components/common/Tooltip'

export const BondList = ({ labelName, tip }: { labelName: string; tip: string }) => {
  const [scheduled, setScheduled] = useState(false)
  const walletType = window.localStorage.getItem('wagmi.wallet')
  const [isScheduledBondOpen, setIsScheduledBondOpen] = useState(false)
  return (
    <>
      <label className="label">
        <>
          <div className="label-text mr-2 flex w-full justify-between">
            <span>
              {labelName} <TooltipElement tip={tip} />
            </span>
            {walletType === '"safe"' && (
              <>
                <ScheduledBondModal
                  close={() => setIsScheduledBondOpen(false)}
                  isOpen={isScheduledBondOpen}
                />
                <span className="btn-group justify-self-end">
                  <button
                    className={`pointer-events-auto hidden w-[85px] sm:btn`}
                    onClick={() => {
                      setScheduled(true)
                      setIsScheduledBondOpen(true)
                    }}
                  >
                    Scheduled Bond
                  </button>
                </span>
              </>
            )}
          </div>
        </>
      </label>
      <BondSelector scheduled={scheduled} />
    </>
  )
}

export const StepOne = () => {
  const { watch } = useFormContext()
  const { address } = useAccount()
  const [bondToAuction] = watch(['bondToAuction'])

  const totalPayment =
    bondToAuction && utils.formatUnits(bondToAuction.maxSupply, bondToAuction.decimals)
  const maturityDate = dayjs(bondToAuction?.maturityDate * 1000)
    .utc()
    .tz()
    .format('LL HH:mm z')

  return (
    <>
      <div className="form-control w-full">
        <BondList
          labelName={'Bond to auction'}
          tip={
            'Bond for which you will be signing a promissory note. Select Scheduled Bond if you are scheduling a promissory note for a bond that has been queued.'
          }
        />

        {bondToAuction && (
          <>
            <div className="form-control w-full">
              <label className="label">
                <TooltipElement
                  left={<span className="label-text">Signing as</span>}
                  tip="Address signing the note"
                />
              </label>
              <div className="w-full text-sm" defaultValue="">
                {address}
              </div>
            </div>

            <div className="form-control w-full">
              <label className="label">
                <TooltipElement
                  left={<span className="label-text">Total Payment Amount</span>}
                  tip="The total amount to be paid denominated in the payment token"
                />
              </label>

              <TokenItem>
                <div>{totalPayment.toLocaleString()}</div>

                <TokenPill token={bondToAuction.paymentToken} />
              </TokenItem>
            </div>

            <div className="form-control w-full">
              <label className="label">
                <TooltipElement
                  left={<span className="label-text">Maturity Date</span>}
                  tip="Date the bond must be paid"
                />
              </label>

              <TokenItem>
                <div>{maturityDate}</div>
              </TokenItem>
            </div>

            {bondToAuction && (
              <span className="card-title border border-[#333333] p-4 text-[#9F9F9F]">
                This signature represents a promise to unconditionally repay the bond in full by the
                maturity date.
              </span>
            )}
          </>
        )}
      </div>
    </>
  )
}
