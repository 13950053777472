import { Signer } from 'ethers'
import { useMemo } from 'react'

import { BigNumber } from '@ethersproject/bignumber'
import { Contract, ContractFunction } from '@ethersproject/contracts'
import { parseUnits } from '@ethersproject/units'
import { Token } from '@josojo/honeyswap-sdk'

import { decodeOrder, encodeOrder, findPreviousOrder, queueStartElement } from './Order'
import { useActiveWeb3React } from './index'
import { useContract } from './useContract'
import depositAndPlaceOrderABI from '../constants/abis/easyAuction/depositAndPlaceOrder.json'
import easyAuctionABI from '../constants/abis/easyAuction/easyAuction.json'
import { Result, useSingleCallResult } from '../state/multicall/hooks'
import { useOrderPlacementState } from '../state/orderPlacement/hooks'
import { AuctionIdentifier } from '../state/orderPlacement/reducer'
import { useOrderbookActionHandlers } from '../state/orderbook/hooks'
import { useOrderActionHandlers } from '../state/orders/hooks'
import { OrderStatus } from '../state/orders/reducer'
import { useTransactionAdder } from '../state/transactions/hooks'
import {
  ChainId,
  DEPOSIT_AND_PLACE_ORDER,
  EASY_AUCTION_NETWORKS,
  calculateGasMargin,
  getContract,
  getEasyAuctionContract,
  getTokenDisplay,
  isTokenWETH,
} from '../utils'
import { abbreviation } from '../utils/numeral'
import { convertPriceIntoBuyAndSellAmount } from '../utils/prices'

import { requiredChain } from '@/connectors'
import { BidsForSingleAuctionQuery } from '@/generated/graphql'

type EstimateAndParams = {
  estimate: ContractFunction<BigNumber>
  method: Function
  args: [number, [string], [string], string] | [number, [string], [string], [string], string]
  value: Maybe<BigNumber>
}

// returns a function that will place an order, if the parameters are all valid
// and the user has approved the transfer of tokens
export function usePlaceOrderCallback(
  auctionIdentifer: AuctionIdentifier,
  auctioningToken: Token,
  biddingToken: Token,
  allOrders: BidsForSingleAuctionQuery,
): null | (() => Promise<string>) {
  const { account, chainId, signer } = useActiveWeb3React()
  const { auctionId } = auctionIdentifer

  const addTransaction = useTransactionAdder()
  const { onNewOrder } = useOrderActionHandlers()
  const { price: priceFromSwapState, sellAmount: bondsToPurchase } = useOrderPlacementState()
  const sellAmount = Number(bondsToPurchase).toString()
  const { onNewBid } = useOrderbookActionHandlers()

  const price = priceFromSwapState.toString()

  const easyAuctionInstance: Maybe<Contract> = useContract(
    EASY_AUCTION_NETWORKS[requiredChain.id as number],
    easyAuctionABI,
  )
  const userId: Result | undefined = useSingleCallResult(easyAuctionInstance, 'getUserId', [
    account == null ? undefined : account,
  ]).result
  return useMemo(() => {
    return async function onPlaceOrder() {
      if (!chainId || !signer || !account || !userId || !auctionId) {
        console.log(!chainId, !signer, !account, !userId, 'missing deps')
        throw new Error('missing dependencies in onPlaceOrder callback')
      }

      const { buyAmountScaled, sellAmountScaled } = convertPriceIntoBuyAndSellAmount(
        auctioningToken,
        biddingToken,
        price,
        sellAmount,
      )

      const currentOrder = {
        buyAmount: buyAmountScaled,
        sellAmount: sellAmountScaled,
        userId: BigNumber.from(parseInt(userId.toString())), // If many people are placing orders, this might be incorrect
      }

      if (sellAmountScaled == undefined || buyAmountScaled == undefined) {
        throw new Error('Price was not correct.')
      }
      const decodedOrders = allOrders.bids.map((bid) => decodeOrder(bid.bytes))
      const foundPreviousOrder = findPreviousOrder(decodedOrders, currentOrder)
      const previousOrder = foundPreviousOrder ? encodeOrder(foundPreviousOrder) : queueStartElement
      console.log('previousOrder', previousOrder)
      const auctioningTokenDisplay = getTokenDisplay(auctioningToken)
      const biddingTokenDisplay = getTokenDisplay(biddingToken)

      const { args, estimate, method, value } = getEstimateParams(
        biddingToken,
        chainId,
        signer,
        account,
        buyAmountScaled,
        sellAmountScaled,
        previousOrder,
        auctionId || 0,
      )

      return estimate(...args, value ? { value } : {})
        .then((estimatedGasLimit) =>
          method(...args, {
            ...(value ? { value } : {}),
            gasLimit: calculateGasMargin(estimatedGasLimit),
            maxPriorityFeePerGas: parseUnits('1.5', 'gwei'),
          }),
        )
        .then((response) => {
          try {
            addTransaction(response?.hash, {
              summary: `Place ${abbreviation(
                sellAmount,
              )} ${biddingTokenDisplay} order for ${auctioningTokenDisplay} on auction ${auctionId}`,
            })

            onNewOrder([
              {
                id: encodeOrder(currentOrder),
                sellAmount: parseFloat(sellAmount).toString(),
                price: price.toString(),
                status: OrderStatus.PENDING,
                chainId,
              },
            ])
            onNewBid({
              volume: parseFloat(sellAmount),
              price: parseFloat(price),
            })
          } catch (e) {
            console.log(e)
          }
          return response
        })
      //.catch((error) => {
      // try { this ain't right. if user rejects it makes a new tx pop-up. could fix with checking if "user rejected transaction" is part of the error but it might be wallet-dependent.
      //   console.log('caught error and retrying', error)
      //   method(...args, {
      //     ...(value ? { value } : {}),
      //     gasLimit: 1000000,
      //     maxPriorityFeePerGas: parseUnits('1.5', 'gwei'),
      //   }).then((response: any) => {
      //     try {
      //       addTransaction(response?.hash, {
      //         summary: `Place ${abbreviation(
      //           sellAmount,
      //         )} ${biddingTokenDisplay} order for ${auctioningTokenDisplay} on auction ${auctionId}`,
      //       })
      //       const order = {
      //         buyAmount: buyAmountScaled,
      //         sellAmount: sellAmountScaled,
      //         userId: BigNumber.from(parseInt(userId.toString())), // If many people are placing orders, this might be incorrect
      //       }
      //       onNewOrder([
      //         {
      //           id: encodeOrder(order),
      //           sellAmount: parseFloat(sellAmount).toString(),
      //           price: price.toString(),
      //           status: OrderStatus.PENDING,
      //           chainId,
      //         },
      //       ])
      //       onNewBid({
      //         volume: parseFloat(sellAmount),
      //         price: parseFloat(price),
      //       })
      //     } catch (e) {
      //       console.log(e)
      //     }
      //     return response
      //   })
      // } catch {
      //   logger.error(`Swap or gas estimate failed`, error)
      //   throw error
      // }
      // })
    }
  }, [
    account,
    allOrders,
    onNewBid,
    onNewOrder,
    addTransaction,
    auctionId,
    auctioningToken,
    biddingToken,
    chainId,
    signer,
    price,
    sellAmount,
    userId,
  ])
}

const getEstimateParams = (
  biddingToken: Token,
  chainId: ChainId,
  signer: Signer,
  account: string,
  buyAmountScaled: BigNumber,
  sellAmountScaled: BigNumber,
  previousOrder: string,
  auctionId: number,
): EstimateAndParams => {
  const easyAuctionContract: Contract = getEasyAuctionContract(signer)
  if (isTokenWETH(biddingToken.address, chainId)) {
    const depositAndPlaceOrderContract = getContract(
      DEPOSIT_AND_PLACE_ORDER[chainId],
      depositAndPlaceOrderABI,
      signer,
    )

    return {
      estimate: depositAndPlaceOrderContract.estimateGas.depositAndPlaceOrder,
      method: depositAndPlaceOrderContract.depositAndPlaceOrder,
      args: [auctionId, [buyAmountScaled.toString()], [previousOrder], '0x'],
      value: sellAmountScaled,
    }
  }
  return {
    estimate: easyAuctionContract.estimateGas.placeSellOrders,
    method: easyAuctionContract.placeSellOrders,
    args: [
      auctionId,
      [buyAmountScaled.toString()],
      [sellAmountScaled.toString()],
      [previousOrder],
      '0x',
    ],
    value: null,
  }
}
