import React from 'react'

export const UNIIcon = () => (
  <svg fill="#fff" height="32" viewBox="0 0 60 60" width="32" xmlns="http://www.w3.org/2000/svg">
    <rect height="60" rx="12" width="60" />
    <g clipPath="url(#clip0_9390_3379)">
      <path
        d="M26.5029 19.0374C26.1104 18.9794 26.0886 18.9648 26.2775 18.9431C26.6337 18.885 27.4623 18.9648 28.0437 19.0955C29.3957 19.4148 30.6095 20.2348 31.9032 21.6789L32.2376 22.0707L32.7246 21.9982C34.8033 21.6644 36.9402 21.9256 38.7282 22.7456C39.2152 22.9706 39.9856 23.4205 40.0801 23.5294C40.1164 23.5656 40.1746 23.8124 40.2109 24.0518C40.3418 24.9299 40.2836 25.5903 40.0074 26.091C39.8548 26.374 39.8548 26.4466 39.9493 26.6933C40.022 26.882 40.2473 27.0126 40.4581 27.0126C40.9087 27.0126 41.3739 26.3015 41.5992 25.3073L41.6937 24.9154L41.8608 25.1041C42.8203 26.1708 43.5689 27.6512 43.6779 28.6962L43.7143 28.9792L43.5471 28.7325C43.2636 28.3043 43.002 28.0213 42.6458 27.7746C42.0062 27.3464 41.3303 27.2158 39.5495 27.1215C37.9359 27.0271 37.0201 26.8965 36.1189 26.599C34.578 26.091 33.793 25.4379 31.9759 23.0214C31.1691 21.9546 30.6603 21.3741 30.1588 20.8879C29.054 19.8211 27.942 19.2624 26.5029 19.0374Z"
        fill="#DB3635"
      />
      <path
        d="M40.4714 21.4103C40.5078 20.6991 40.6023 20.2274 40.8058 19.7993C40.8785 19.6324 40.9584 19.48 40.973 19.48C40.9948 19.48 40.9511 19.6106 40.9003 19.763C40.7476 20.1766 40.7331 20.7572 40.8276 21.4103C40.9584 22.2521 41.0166 22.3682 41.9178 23.2825C42.3321 23.7107 42.8191 24.255 43.0081 24.4799L43.3279 24.8936L43.0081 24.596C42.6156 24.2187 41.7143 23.5075 41.5108 23.4132C41.38 23.3406 41.3582 23.3406 41.2637 23.4349C41.191 23.5075 41.1692 23.6236 41.1692 24.1679C41.1474 25.0097 41.0384 25.5321 40.7549 26.0764C40.6023 26.3594 40.5877 26.3014 40.7186 25.9821C40.813 25.7353 40.8276 25.6265 40.8276 24.821C40.8276 23.1955 40.6386 22.7963 39.4975 22.1432C39.214 21.9763 38.727 21.7296 38.4509 21.599C38.1529 21.4683 37.9275 21.3522 37.9421 21.3522C37.9784 21.3159 39.0832 21.6352 39.5193 21.8167C40.1734 22.0779 40.2897 22.0997 40.3624 22.0779C40.4133 22.0344 40.4496 21.882 40.4714 21.4103Z"
        fill="#DB3635"
      />
      <path
        d="M27.2717 24.2045C26.4867 23.116 25.9779 21.4324 26.0869 20.177L26.1233 19.7852L26.3123 19.8214C26.6466 19.8795 27.2281 20.1045 27.5115 20.2714C28.2602 20.7213 28.6018 21.3381 28.9143 22.8765C29.0088 23.3265 29.1396 23.849 29.1978 24.0159C29.2923 24.2989 29.6484 24.952 29.9464 25.3656C30.1499 25.6631 30.0191 25.8155 29.5539 25.7793C28.8416 25.6994 27.8895 25.0463 27.2717 24.2045Z"
        fill="#DB3635"
      />
      <path
        d="M39.5126 32.3463C35.7985 30.8514 34.4902 29.5597 34.4902 27.3681C34.4902 27.0488 34.512 26.7876 34.512 26.7876C34.5338 26.7876 34.6647 26.8964 34.8318 27.0343C35.5805 27.6366 36.4236 27.8979 38.7713 28.2317C40.1377 28.4349 40.93 28.5873 41.6423 28.834C43.91 29.5815 45.32 31.1199 45.6544 33.1953C45.7489 33.7976 45.6907 34.9369 45.5454 35.532C45.4145 35.9964 45.0366 36.86 44.9421 36.8818C44.9203 36.8818 44.8839 36.7874 44.8839 36.635C44.8476 35.8513 44.4551 35.0966 43.7937 34.5161C43.0014 33.8484 41.9911 33.3405 39.5126 32.3463Z"
        fill="#DB3635"
      />
      <path
        d="M36.889 32.9632C36.8527 32.6802 36.7582 32.3246 36.7 32.1795L36.6056 31.8965L36.7727 32.0997C37.0198 32.3827 37.2015 32.7165 37.376 33.1882C37.5068 33.5438 37.5068 33.6526 37.5068 34.2332C37.5068 34.792 37.485 34.9226 37.376 35.2419C37.187 35.7499 36.9617 36.1054 36.591 36.4973C35.9151 37.1867 35.0356 37.5641 33.7782 37.731C33.5529 37.7527 32.9133 37.8035 32.3536 37.8398C30.9508 37.9124 30.0132 38.0648 29.1628 38.3623C29.0538 38.3986 28.9375 38.4349 28.9157 38.4204C28.8793 38.3841 29.4608 38.043 29.926 37.818C30.5801 37.4987 31.2561 37.3318 32.7388 37.0706C33.4729 36.9617 34.2215 36.8093 34.4105 36.7368C36.2712 36.1635 37.187 34.7266 36.889 32.9632Z"
        fill="#DB3635"
      />
      <path
        d="M38.5969 35.9818C38.1099 34.915 37.9936 33.9063 38.2625 32.9484C38.2989 32.8541 38.3352 32.7598 38.3715 32.7598C38.4079 32.7598 38.5242 32.8178 38.6332 32.8904C38.8585 33.0428 39.3237 33.304 40.5302 33.9571C42.0493 34.7844 42.9143 35.4157 43.5103 36.1487C44.0336 36.7873 44.3534 37.513 44.506 38.4128C44.6005 38.9208 44.5424 40.1326 44.4115 40.6406C43.9972 42.2299 43.0596 43.507 41.6932 44.2327C41.4897 44.3416 41.3152 44.4214 41.3007 44.4214C41.2862 44.4214 41.3588 44.2327 41.4679 44.0078C41.9185 43.0499 41.9766 42.1355 41.635 41.1051C41.4315 40.4665 40.9954 39.7045 40.1378 38.4055C39.0984 36.9179 38.8585 36.5188 38.5969 35.9818Z"
        fill="#DB3635"
      />
      <path
        d="M24.6269 41.7076C26.0151 40.5465 27.7232 39.7265 29.2931 39.458C29.9691 39.3492 31.0957 39.3854 31.7135 39.5524C32.7093 39.8136 33.6105 40.3796 34.0757 41.069C34.5263 41.7439 34.7298 42.3244 34.9406 43.6161C35.0133 44.1241 35.1078 44.6466 35.1296 44.7555C35.2822 45.4303 35.5802 45.9528 35.9582 46.2358C36.5397 46.664 37.55 46.6858 38.5457 46.3084C38.7129 46.2503 38.8655 46.1995 38.8655 46.2141C38.9019 46.2503 38.4004 46.5914 38.0587 46.7583C37.5936 47.005 37.2156 47.0776 36.7068 47.0776C35.8056 47.0776 35.0351 46.6132 34.4173 45.6771C34.2865 45.4884 34.0248 44.9441 33.7995 44.4434C33.1454 42.9267 32.8037 42.4768 32.0333 41.9689C31.3573 41.5407 30.4924 41.4464 29.8383 41.7657C28.9733 42.1793 28.748 43.2823 29.3513 43.9572C29.5984 44.2402 30.0418 44.4652 30.4197 44.516C31.1102 44.6103 31.7135 44.0661 31.7135 43.3767C31.7135 42.9267 31.5463 42.6655 31.0957 42.4623C30.4924 42.2011 29.8383 42.4986 29.8601 43.0791C29.8601 43.3259 29.9691 43.471 30.2162 43.5871C30.3688 43.6597 30.3688 43.6597 30.2526 43.6452C29.7074 43.5363 29.5766 42.8614 30.0054 42.426C30.5288 41.9035 31.6335 42.1285 32.0115 42.8542C32.1641 43.1517 32.1787 43.754 32.0478 44.1241C31.728 44.9514 30.8268 45.3795 29.9109 45.1328C29.2931 44.9659 29.0315 44.799 28.2828 44.0298C26.9673 42.68 26.4658 42.4188 24.5905 42.1358L24.2344 42.0777L24.6269 41.7076Z"
        fill="#DB3635"
      />
      <path
        clipRule="evenodd"
        d="M14.7638 11.5848C19.132 16.8823 25.8625 25.1187 26.2041 25.5686C26.4876 25.946 26.3713 26.3016 25.9061 26.5628C25.6445 26.7152 25.0993 26.8603 24.8377 26.8603C24.5397 26.8603 24.1835 26.7079 23.9364 26.4685C23.7692 26.3016 23.0351 25.2348 21.3852 22.6659C20.1278 20.6993 19.0593 19.0738 19.0448 19.052C18.9721 19.0157 18.9721 19.0157 21.2544 23.0941C22.7008 25.6557 23.166 26.5773 23.166 26.6862C23.166 26.9329 23.0933 27.0635 22.788 27.3973C22.2792 27.9561 22.0539 28.5947 21.8867 29.9227C21.6977 31.4031 21.1962 32.4481 19.7498 34.226C18.9067 35.2709 18.7759 35.4596 18.5651 35.895C18.3034 36.4175 18.2308 36.7223 18.1871 37.3899C18.1508 38.1011 18.2235 38.551 18.4343 39.2259C18.6232 39.8282 18.8268 40.2201 19.3355 40.9893C19.7644 41.6642 20.026 42.1722 20.026 42.3536C20.026 42.506 20.0624 42.506 20.7383 42.3536C22.3519 41.9762 23.682 41.3449 24.4161 40.5539C24.8667 40.0677 24.9758 39.8064 24.9758 39.1316C24.9758 38.7034 24.954 38.6091 24.8449 38.3478C24.656 37.9342 24.2998 37.6004 23.5294 37.0779C22.5191 36.3885 22.083 35.8225 21.9739 35.075C21.8795 34.4364 21.9957 34.0083 22.5554 32.8254C23.1369 31.6063 23.2895 31.1056 23.384 29.8646C23.4421 29.0809 23.5366 28.7616 23.762 28.5149C24.0091 28.2536 24.2126 28.1593 24.7941 28.0867C25.7535 27.9561 26.3713 27.7094 26.8583 27.2449C27.2871 26.8531 27.4761 26.4612 27.4979 25.8807L27.5197 25.4525L27.2726 25.1913C26.3858 24.1826 14.1823 10.7793 14.1241 10.7793C14.1023 10.7793 14.4076 11.1349 14.7638 11.5848ZM20.5203 38.2099C20.7238 37.8544 20.6148 37.4044 20.2732 37.1795C19.9533 36.9763 19.4664 37.0706 19.4664 37.3464C19.4664 37.419 19.5027 37.4988 19.619 37.5351C19.7862 37.6294 19.808 37.7237 19.6772 37.9269C19.5463 38.1301 19.5463 38.3188 19.7135 38.4494C19.9752 38.6599 20.3313 38.5438 20.5203 38.2099Z"
        fill="#DB3635"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M28.1156 28.3624C27.6649 28.493 27.2361 28.9792 27.1053 29.4654C27.0326 29.7629 27.0689 30.3072 27.1997 30.4741C27.4033 30.7353 27.5922 30.8079 28.1156 30.8079C29.1477 30.8079 30.0271 30.358 30.1216 29.8137C30.2161 29.3638 29.8236 28.747 29.2785 28.4639C28.995 28.3188 28.4136 28.268 28.1156 28.3624ZM29.3148 29.2985C29.4675 29.0735 29.4093 28.834 29.1259 28.6599C28.6171 28.3406 27.8539 28.6018 27.8539 29.088C27.8539 29.3348 28.2464 29.596 28.6243 29.596C28.8642 29.596 29.1985 29.4436 29.3148 29.2985Z"
        fill="#DB3635"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_9390_3379">
        <rect
          fill="white"
          height="36.4286"
          transform="translate(13.9277 10.7144)"
          width="32.1429"
        />
      </clipPath>
    </defs>
  </svg>
)
