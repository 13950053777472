import React from 'react'

import { StepThree } from './StepThree'
import { StepTwo } from './StepTwo'
import { Summary } from './Summary'
import { BondActionSteps } from '../BondActionSteps'
import { FormSteps } from '../FormSteps'
import { StepOne } from '../StepOne'

const SetupProduct = () => {
  const midComponents = [<StepOne key={0} />, <StepTwo key={1} />, <StepThree key={2} />]
  const steps = ['Bond', 'Collateral', 'Convertibility', 'Confirm']

  return (
    <FormSteps
      ActionSteps={BondActionSteps}
      Summary={Summary}
      color="purple"
      convertible
      midComponents={midComponents}
      steps={steps}
      title="Convertible Bond Creation"
    />
  )
}

export default SetupProduct
