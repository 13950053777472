import React from 'react'

import dayjs from 'dayjs'
import { round } from 'lodash'
import { useFormContext } from 'react-hook-form'

import { limitOrderType } from './ConfigureLimitOrder'

import { SummaryItem } from '@/components/ProductCreate/SummaryItem'
import { calculateInterestRate } from '@/components/form/InterestRateInputPanel'
import { useBond } from '@/hooks/useBond'
import { currentTimeInUTC } from '@/utils/tools'

export const Summary = () => {
  const { watch } = useFormContext()

  const [borrowToken, numberOfBonds, orderType, bondToAuction, numberOfBorrowTokens, expiry] =
    watch([
      'borrowToken',
      'numberOfBonds',
      'orderType',
      'bondToAuction',
      'numberOfBorrowTokens',
      'expiry',
    ])
  // default to 0x0 otherwise the useBond hook will fail
  const bondId = bondToAuction?.id || '0x0'

  const { data } = useBond(bondId)
  const bondPrice = data?.clearingPrice
  const auctionEndDate = data?.auctions[0]?.end
  const hasAuctionEnded = dayjs.unix(auctionEndDate).isAfter(new Date())
  const maturityDate = data?.maturityDate
  const ytm = calculateInterestRate({
    price: bondPrice,
    maturityDate: maturityDate,
    startDate: auctionEndDate,
  })
  const tokenYouHave = orderType === limitOrderType.Buy ? 'USDC' : bondToAuction?.name
  const tokenAmountYouGive = orderType === limitOrderType.Buy ? numberOfBorrowTokens : numberOfBonds
  const tokenYouGet = orderType === limitOrderType.Buy ? bondToAuction?.name : 'USDC'
  const tokenAmountYouGet = orderType === limitOrderType.Buy ? numberOfBonds : numberOfBorrowTokens

  const pricePerBond = Number(numberOfBorrowTokens / numberOfBonds).toLocaleString(undefined, {
    maximumFractionDigits: 3,
  })

  const startDate = currentTimeInUTC() / 1000
  const currentYTM = calculateInterestRate({
    price: pricePerBond,
    maturityDate: maturityDate,
    startDate,
  })

  const auctionPricePerBond = round(data?.clearingPrice?.toLocaleString(), 4)

  return (
    <div className="card w-[425px] overflow-visible">
      <div className="card-body">
        <h1 className="card-title border-b border-[#2C2C2C] pb-4 !text-xs uppercase">{`${orderType} Bonds for USDC`}</h1>
        <div className="space-y-4">
          {tokenYouHave && tokenYouGet && (
            <>
              {tokenAmountYouGive && (
                <SummaryItem
                  text={tokenAmountYouGive + ' ' + tokenYouHave}
                  tip="The amount and token you will be using for the limit order. If you are buying bonds, you will be using USDC. If you are selling bonds, you will be using the bond you are selling. This token must be approved for transfer for the order to be valid. The amount is for the entire order."
                  title={orderType === 'Buy' ? 'Exchange token and amount' : 'Bond amount you have'}
                />
              )}
              {tokenAmountYouGet && (
                <SummaryItem
                  text={tokenAmountYouGet + ' ' + tokenYouGet}
                  tip="The amount and token you will be receiving for the entire limit order. If you are buying bonds, you will be receiving the bond you are buying. If you are selling bonds, you will be receiving USDC."
                  title={
                    orderType === 'Buy' ? 'Bond and amount you buy' : 'Token and amount you receive'
                  }
                />
              )}
              {numberOfBonds && numberOfBorrowTokens && (
                <SummaryItem
                  text={pricePerBond}
                  tip="Price of each bond. Denominated in the borrow token."
                  title="Price per Bond"
                />
              )}
              {numberOfBonds && numberOfBorrowTokens && (
                <SummaryItem
                  text={`${currentYTM} YTM`}
                  tip="Yield to maturity of the bond for the given parameters."
                  title="Yield to maturity"
                />
              )}
              {data && hasAuctionEnded && (
                <>
                  <SummaryItem
                    text={`${auctionPricePerBond} Price per Bond @ auction`}
                    tip="Clearing price of the bond at the most recent auction."
                    title="Price per bond at auction"
                  />
                  <SummaryItem
                    text={`${ytm} YTM @ auction`}
                    tip="Yield to maturity at the end of the most recent auction."
                    title="Yield to maturity at auction"
                  />
                </>
              )}
            </>
          )}
          <SummaryItem
            text={`Order expires ${dayjs(expiry).fromNow()}`}
            tip="When the order will not be valid for filling."
            title="Order expiration date"
          />
          {numberOfBonds && bondToAuction && borrowToken && numberOfBorrowTokens && (
            <SummaryItem
              text={`Creating this order will ${
                orderType === limitOrderType.Buy || orderType === null ? 'buy' : 'sell'
              } ${numberOfBonds.toString()} ${
                bondToAuction.name
              } in exchange for ${numberOfBorrowTokens} ${
                borrowToken.name
              } at ${pricePerBond.toString()} ${borrowToken.name} per bond.`}
              tip="Summary of transaction"
              title="Summary"
            />
          )}
        </div>
      </div>
    </div>
  )
}
