import React from 'react'
import { useNavigate } from 'react-router-dom'

import { CrossCircledIcon } from '@radix-ui/react-icons'

import useLocalStorage from '@/hooks/useLocalStorage'

export type Campaign = {
  key: string
  start: number
  end: number
  link: string
  title: string
  message: string
}

export default function Banner({ campaign }: { campaign: Campaign }) {
  const { key, link, message, title } = campaign
  // Unique key per campaign. Update upon new message so previous dismissals are ignored.
  const [hasDismissed, setHasDismissed] = useLocalStorage(`banner:${key}`, false)

  // Update this with the end date of the campaign. For auctions, the end date.
  const navigate = useNavigate()
  if (hasDismissed) return null

  return (
    <div className="flex items-center gap-x-6 bg-gray-900 py-2.5 px-6 sm:px-3.5 sm:before:flex-1">
      <p className="text-sm leading-6 text-white">
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate(link)
          }}
        >
          <strong className="animate-pulse font-semibold text-[#17f215]">{title}</strong>
          <svg
            aria-hidden="true"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
            viewBox="0 0 2 2"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          {message}
          <span aria-hidden="true"> &rarr;</span>
        </div>
      </p>
      <div className="flex flex-1 justify-end">
        <button className="-m-3 p-3 focus-visible:outline-offset-[-4px]" type="button">
          <span className="sr-only">Dismiss</span>
          <CrossCircledIcon
            aria-hidden="true"
            className="h-5 w-5 text-white"
            onClick={() => setHasDismissed(true)}
          />
        </button>
      </div>
    </div>
  )
}
