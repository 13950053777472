import { VoidSigner, ethers } from 'ethers'

import {
  AbiItem,
  EIP712TypedData,
  LimitOrder,
  LimitOrderBuilder,
  LimitOrderData,
  LimitOrderPredicateBuilder,
  LimitOrderProtocolFacade,
  LimitOrderSignature,
  NonceSeriesV2,
  ProviderConnector,
  SeriesNonceManagerFacade,
  SeriesNonceManagerPredicateBuilder,
  limirOrderProtocolAdresses,
  seriesNonceManagerContractAddresses,
} from '@1inch/limit-order-protocol-utils'

import { ONE_INCH_API, ONE_INCH_ENDPOINT } from '@/hooks/useOrderbook'

export type LimitOrderAll = {
  signature: string
  orderHash: string
  createDateTime: string
  remainingMakerAmount: string
  makerBalance: string
  makerAllowance: string
  data: {
    makerAsset: string
    takerAsset: string
    salt: string
    receiver: string
    allowedSender: string
    makingAmount: string
    takingAmount: string
    maker: string
    interactions: string
    offsets: string
  }
  makerRate: string
  takerRate: string
  isMakerContract: boolean
  orderInvalidReason: string
}

export class EthersSignerConnector implements ProviderConnector {
  constructor(protected readonly _signer: VoidSigner) {}
  contractEncodeABI(
    abi: AbiItem[],
    address: string | null,
    methodName: string,
    methodParams: unknown[],
  ): string {
    const iface = new ethers.utils.Interface(abi)
    return iface.encodeFunctionData(methodName, methodParams)
  }

  /* eslint @typescript-eslint/no-unused-vars: off */
  /* eslint unused-imports/no-unused-vars: off */
  signTypedData(
    walletAddress: string,
    typedData: EIP712TypedData,
    _typedDataHash: string,
  ): Promise<string> {
    // Specifically remove EIP712Domain when signing as ethers automatically computes it
    // However, it is necessary exist on the object when hashing
    const { EIP712Domain, ...types } = typedData.types
    return this._signer._signTypedData(typedData.domain, types, typedData.message)
  }
  ethCall(contractAddress: string, callData: string): Promise<string> {
    return this._signer.call({
      to: contractAddress,
      data: callData,
    })
  }
  decodeABIParameter<T>(type: string, hex: string): T {
    const abiCoder = new ethers.utils.AbiCoder()
    return abiCoder.decode([type], hex)[0]
  }
  decodeABICallParameters<T>(types: Array<string>, callData: string): T {
    const abiCoder = new ethers.utils.AbiCoder()
    return abiCoder.decode(types, callData)[0]
  }
}

export const createLimitOrder = async (
  limitOrderData: LimitOrderData,
  { chainId, expiration, signer },
) => {
  const walletAddress = await signer.getAddress()
  const connector = new EthersSignerConnector(signer)
  const contractAddress = limirOrderProtocolAdresses[chainId]
  const seriesContractAddress = seriesNonceManagerContractAddresses[chainId]

  const limitOrderProtocolFacade = new LimitOrderProtocolFacade(contractAddress, chainId, connector)
  const seriesNonceManagerFacade = new SeriesNonceManagerFacade(
    seriesContractAddress,
    chainId,
    connector,
  )
  const seriesNonceManagerPredicateBuilder = new SeriesNonceManagerPredicateBuilder(
    seriesNonceManagerFacade,
  )
  const limitOrderPredicateBuilder = new LimitOrderPredicateBuilder(limitOrderProtocolFacade)
  const limitOrderBuilder = new LimitOrderBuilder(contractAddress, chainId, connector)

  const nonce = 0
  // Creates predicate that restricts Limit Order invalidation conditions
  // Because timestampBelowAndNonceEquals is method of another contract arbitraryStaticCall() is necessary
  const simpleLimitOrderPredicate = limitOrderPredicateBuilder.arbitraryStaticCall(
    // is type LimitOrderPredicateCallData
    seriesNonceManagerPredicateBuilder.facade,
    seriesNonceManagerPredicateBuilder.timestampBelowAndNonceEquals(
      NonceSeriesV2.LimitOrderV3,
      expiration,
      nonce,
      walletAddress,
    ),
  )

  // Create a limit order and it's signature
  const limitOrder = limitOrderBuilder.buildLimitOrder({
    ...limitOrderData,
    predicate: simpleLimitOrderPredicate,
  })

  const limitOrderTypedData = limitOrderBuilder.buildLimitOrderTypedData(limitOrder)
  const signature = await limitOrderBuilder.buildOrderSignature(walletAddress, limitOrderTypedData)

  const orderHash = limitOrderBuilder.buildLimitOrderHash(limitOrderTypedData)
  const signedOrder = {
    orderHash,
    signature,
    data: limitOrder,
  }
  const response = await fetch(`${ONE_INCH_API[chainId]}${ONE_INCH_ENDPOINT[chainId]['order']}`, {
    method: 'POST',
    body: JSON.stringify(signedOrder),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  if (response.status == 201) {
    return true
  } else if (response.status == 400) {
    console.error('Order data invalid')
  } else {
    console.error('Order creation failed')
  }
  return false
}

export const fillLimitOrder = async (
  order: LimitOrder,
  signature: LimitOrderSignature,
  { chainId, signer, takingAmount },
) => {
  const connector = new EthersSignerConnector(signer)
  const contractAddress = limirOrderProtocolAdresses[chainId]

  const limitOrderProtocolFacade = new LimitOrderProtocolFacade(contractAddress, chainId, connector)

  // This creates a transaction that will calculate how many of maker based on taker
  const callData = limitOrderProtocolFacade.fillLimitOrder({
    order,
    signature: signature == '' ? '0x' : signature,
    makingAmount: '0',
    takingAmount,
    thresholdAmount: '0',
  })
  const tx = await signer.sendTransaction({
    to: contractAddress,
    data: callData,
  })
  const receipt = await tx.wait()
  if (receipt.status == 1) {
    return true
  }
  return false
}

export const cancelLimitOrder = async (order: LimitOrder, { chainId, signer }) => {
  const connector = new EthersSignerConnector(signer)
  const contractAddress = limirOrderProtocolAdresses[chainId]

  const limitOrderProtocolFacade = new LimitOrderProtocolFacade(contractAddress, chainId, connector)

  const callData = limitOrderProtocolFacade.cancelLimitOrder(order)

  const tx = await signer.sendTransaction({
    to: contractAddress,
    data: callData,
  })
  const receipt = await tx.wait()
  if (receipt.status == 1) {
    return true
  }
  return false
}
