import { BigNumber } from 'ethers'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { parseUnits } from '@ethersproject/units'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { useFormContext } from 'react-hook-form'
import { useContract, useContractRead, useToken } from 'wagmi'

import { MintAction } from './MintAction'
import { ActionButton } from '../auction/Claimer'
import WarningModal from '../modals/WarningModal'

import Tooltip from '@/components/common/Tooltip'
import { requiredChain } from '@/connectors'
import BOND_ABI from '@/constants/abis/bond.json'
import { V1_BOND_FACTORY_ADDRESS } from '@/constants/v1'
import { useActiveWeb3React } from '@/hooks'

export const BondActionSteps = ({ convertible = true, disabled }) => {
  const { account, signer } = useActiveWeb3React()
  const { getValues } = useFormContext()
  const navigate = useNavigate()
  const [transactionError, setTransactionError] = useState('')
  const [hasScheduledTransaction, setHasScheduledTransaction] = useState<boolean>(false)
  const walletType = window.localStorage.getItem('wagmi.wallet')

  const [collateralToken, amountOfcollateral] = getValues(['collateralToken', 'amountOfCollateral'])
  const { data: collateralTokenData } = useToken({
    address: collateralToken?.address,
  })

  const { data: bondAllowance } = useContractRead<typeof BOND_ABI, 'allowance', BigNumber>({
    address: collateralTokenData?.address,
    abi: BOND_ABI,
    functionName: 'allowance',
    args: [account, V1_BOND_FACTORY_ADDRESS[requiredChain.id]],
  })

  // state 0 for none, 1 for metamask confirmation, 2 for block confirmation
  const [waitingWalletApprove, setWaitingWalletApprove] = useState(0)
  const [currentApproveStep, setCurrentApproveStep] = useState(0)
  const addRecentTransaction = useAddRecentTransaction()
  const contract = useContract({
    address: collateralTokenData?.address,
    abi: BOND_ABI,
    signerOrProvider: signer,
  })

  useEffect(() => {
    // Already approved the token
    const hasApprovedEasyAuction =
      bondAllowance &&
      collateralTokenData?.decimals &&
      bondAllowance.gte(parseUnits(`${amountOfcollateral || 0}`, collateralTokenData?.decimals))
    if (hasApprovedEasyAuction || hasScheduledTransaction) {
      setCurrentApproveStep(1)
    }
  }, [bondAllowance, amountOfcollateral, collateralTokenData, hasScheduledTransaction])

  const confirmSteps = [
    {
      text: `Approve ${
        collateralTokenData?.symbol || collateralToken?.name || 'token'
      } as collateral`,
      tip: 'The collateral token needs to be approved so it can be transferred into the bond contract and used as collateral.',
    },
    {
      text: `Mint ${collateralTokenData?.symbol || collateralToken?.name} ${
        convertible ? 'Convertible' : 'Simple'
      } Bonds`,
      tip: 'Mint the bonds to the connected wallet.',
    },
  ]

  return (
    <>
      <ul className="steps steps-vertical">
        {confirmSteps.map((step, i) => (
          <li className={`step ${i < currentApproveStep ? 'checked step-primary' : ''}`} key={i}>
            <Tooltip left={step.text} tip={step.tip} />
          </li>
        ))}
      </ul>
      {!currentApproveStep && (
        <>
          <ActionButton
            className={waitingWalletApprove ? 'loading' : ''}
            color="blue"
            disabled={disabled}
            onClick={() => {
              setWaitingWalletApprove(1)
              contract
                .approve(
                  V1_BOND_FACTORY_ADDRESS[requiredChain.id],
                  parseUnits(`${amountOfcollateral || 0}`, collateralToken?.decimals),
                )
                .then((result) => {
                  setWaitingWalletApprove(2)
                  addRecentTransaction({
                    hash: result?.hash,
                    description: `Approve ${
                      collateralToken?.symbol || collateralToken?.name
                    } for ${amountOfcollateral}`,
                  })
                  return result.wait()
                })
                .then(() => {
                  setCurrentApproveStep(1)
                })
                .catch((e) => {
                  setWaitingWalletApprove(0)
                  setTransactionError(e?.message || e)
                })
            }}
          >
            {!waitingWalletApprove && `Approve token for sale`}
            {waitingWalletApprove === 1 && 'Confirm approval in wallet'}
            {waitingWalletApprove === 2 &&
              `Approving ${collateralToken?.symbol || collateralToken?.name}...`}
          </ActionButton>
          <OverrideAllowanceCheck
            handleClick={() => {
              setHasScheduledTransaction(true)
            }}
            walletType={walletType}
          />
        </>
      )}
      {currentApproveStep === 1 && (
        <MintAction
          convertible={convertible}
          disabled={disabled}
          setCurrentApproveStep={setCurrentApproveStep}
        />
      )}
      {currentApproveStep === 2 && (
        <ActionButton
          onClick={() => {
            navigate('/auctions/create')
          }}
        >
          Create auction
        </ActionButton>
      )}
      <WarningModal
        content={transactionError}
        isOpen={!!transactionError}
        onDismiss={() => {
          setTransactionError('')
        }}
      />
    </>
  )
}

export const OverrideAllowanceCheck = ({
  handleClick,
  walletType,
}: {
  walletType: string
  handleClick: () => void
}) => {
  if (walletType !== '"safe"') return null
  return (
    <div className="">
      <div>
        <h1 className="pb-2">Is the approval already scheduled?</h1>
      </div>
      <Tooltip
        left={
          <div className="btn-group">
            <button className={`pointer-events-auto hidden w-[150px] sm:btn`} onClick={handleClick}>
              Override ALlowance Check
            </button>
          </div>
        }
        tip="Bypass the approval process if the token approval has been scheduled in a previous transaction and is queued. IMPORTANT: Since the tokens have yet to be approved, gas will be automatically set to 1000000 Wei."
      />
    </div>
  )
}
