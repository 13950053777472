import React from 'react'

import { LimitOrder } from '@1inch/limit-order-protocol-utils'
import { parseUnits } from '@ethersproject/units'
import { ErrorMessage } from '@hookform/error-message'
import { CrossCircledIcon } from '@radix-ui/react-icons'
import { useFormContext } from 'react-hook-form'
import { useBalance, useToken } from 'wagmi'

import { BorrowTokens, ONE_INCH_ROUTER_V5 } from '../ProductCreate/SelectableTokens'
import { ActionButton } from '../auction/Claimer'
import { ApproveButton } from '../buttons/ApproveButton'
import MyModal from '../modals/common/Modal'

import TooltipElement from '@/components/common/Tooltip'
import { useActiveWeb3React } from '@/hooks'
import { cancelLimitOrder, fillLimitOrder } from '@/pages/BondDetail/OrderbookApi'
import { useWalletModalToggle } from '@/state/application/hooks'

const FillOrderModal = ({
  abortModal,
  close,
  data,
  isOpen,
}: {
  close: () => void
  isOpen: boolean
  abortModal: () => void
  acceptTerms: () => void
  data: {
    limitOrder: LimitOrder
    fillableAmountInMakerAsset: string
    signature: string
    isSell: boolean
    pricePerBond: string
  }
}) => {
  const { fillableAmountInMakerAsset, isSell, limitOrder, pricePerBond, signature } = data || {}

  const { account, chainId, signer } = useActiveWeb3React()
  const {
    formState: { errors, isValid },
    register,
    watch,
  } = useFormContext()
  const [orderAmount] = watch(['orderAmount'])
  const { data: borrowTokenData } = useToken({ address: BorrowTokens[chainId][0].address })
  const toggleWalletModal = useWalletModalToggle()

  const { data: takerBalance } = useBalance({
    address: account,
    token: limitOrder?.takerAsset,
    formatUnits: borrowTokenData?.decimals,
  })
  if (!data) return null
  const bondPrice = (orderAmount * Number(pricePerBond)).toLocaleString(undefined, {
    maximumFractionDigits: 3,
  })
  const usdcPrice = (orderAmount / Number(pricePerBond)).toLocaleString(undefined, {
    maximumFractionDigits: 3,
  })
  const handleFill = () => {
    const takingAmount = parseUnits(orderAmount, borrowTokenData?.decimals)
    fillLimitOrder(limitOrder, signature, {
      chainId,
      signer,
      takingAmount,
    })
  }
  const isUsersOrder = account && account.toLowerCase() === limitOrder?.maker.toLowerCase()
  return (
    <MyModal blockBackdropDismiss hideCloseIcon isOpen={isOpen} onDismiss={close}>
      <div className="mt-2 space-y-6 text-center">
        <h1 className="text-xl font-medium text-[#E0E0E0]">Fill Limit Order</h1>
        <div className="space-y-4 text-left text-[#D6D6D6]">
          {/* isSell refers to the limit order being a Sell order (meaning a user made an order to sell bonds). On this page, its from the perspective of the filler of the bond. So if it is a sell Order, the reader will be buying bonds.*/}
          <p>
            There {fillableAmountInMakerAsset != '1' ? 'are' : 'is'}{' '}
            {` ${fillableAmountInMakerAsset} ${isSell ? 'Bonds' : 'USDC'} `}
            remaining in the chosen limit order.
          </p>
          <div className="form-control w-full">
            <label className="label">
              <TooltipElement
                left={
                  isUsersOrder ? (
                    'This is your limit order'
                  ) : (
                    <span className="label-text">
                      How many {isSell ? 'USDC' : 'Bonds'} would you like to sell?
                    </span>
                  )
                }
              />
            </label>
            <input
              className="input-bordered input w-full"
              disabled={!account || isUsersOrder}
              inputMode="numeric"
              onWheel={({ target }) => (target as HTMLInputElement).blur()}
              placeholder="0"
              type="number"
              {...register('orderAmount', {
                required: 'Some bonds must be traded',
                validate: {
                  greaterThanZero: (value) => value > 0 || 'Some bonds must be traded',
                  lessThanBalance: (value) => {
                    return (
                      value <= Number(takerBalance?.formatted) ||
                      `The current wallet's balance (${takerBalance?.formatted}) does not cover the amount of tokens to be sold.`
                    )
                  },
                  lessThanOrder: (value) => {
                    return (
                      (isSell ? value : value * Number(pricePerBond)) <=
                        Number(fillableAmountInMakerAsset) ||
                      `There are not enough tokens in the order. Maximum amount is ${
                        isSell
                          ? fillableAmountInMakerAsset
                          : Number(fillableAmountInMakerAsset) / Number(pricePerBond)
                      }`
                    )
                  },
                },
              })}
            />
          </div>
          {bondPrice && orderAmount && (
            <p>
              {`By executing the transaction, you will ${isSell ? 'buy' : 'sell'} ${
                isSell
                  ? `~${usdcPrice} Bonds for ${orderAmount} USDC.`
                  : `${orderAmount} Bonds for ~${bondPrice} USDC.`
              }`}
            </p>
          )}
        </div>
        {Object.keys(errors).length > 0 && (
          <div className="rounded-md bg-zinc-800 p-4">
            <div className="flex">
              <div className="shrink-0">
                <CrossCircledIcon className="h-5 w-5 text-red-800" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium">There was an error with your submission</h3>
                <div className="mt-2 text-left text-sm">
                  <ul className="list-disc space-y-1 pl-5" role="list">
                    {Object.keys(errors).map((name) => (
                      <ErrorMessage
                        errors={errors}
                        key={name}
                        name={name}
                        render={({ message }) => <li>{message}</li>}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="space-x-4">
          {!account ? (
            <div className="inline-flex w-[170px]">
              <ActionButton
                className="btn-sm btn border-none bg-transparent text-[#1c701c] hover:text-white"
                onClick={toggleWalletModal}
              >
                Connect Wallet
              </ActionButton>
            </div>
          ) : (
            <div className="inline-flex w-[170px]">
              {isUsersOrder ? (
                <ActionButton
                  className="border-none bg-transparent text-[#1c701c] hover:text-white hover:decoration-transparent"
                  color="purple"
                  onClick={() => cancelLimitOrder(limitOrder, { chainId, signer })}
                >
                  Cancel Order
                </ActionButton>
              ) : (
                <ApproveButton
                  amountToCheck={limitOrder.takingAmount}
                  contract={limitOrder.takerAsset}
                  smallText={true}
                  spender={ONE_INCH_ROUTER_V5[chainId]}
                >
                  <ActionButton
                    className="border-none bg-transparent text-[#1c701c] hover:text-white hover:decoration-transparent"
                    onClick={handleFill}
                  >
                    Fill Order
                  </ActionButton>
                </ApproveButton>
              )}
            </div>
          )}
          <button
            className="btn-sm btn h-[41px] w-[170px] bg-[#696969] font-normal normal-case text-white hover:bg-gray-500"
            onClick={abortModal}
          >
            Close
          </button>
        </div>
      </div>
    </MyModal>
  )
}

export default FillOrderModal
