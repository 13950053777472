import { BigNumber } from '@ethersproject/bignumber'

export interface SellOrder {
  sellAmount: BigNumber
  buyAmount: BigNumber
}

export interface Order {
  sellAmount: BigNumber
  buyAmount: BigNumber
  userId: BigNumber
}

export function decodeOrder(bytes: string): Order {
  return {
    userId: BigNumber.from('0x' + bytes.substring(2, 18)),
    buyAmount: BigNumber.from('0x' + bytes.substring(19, 42)),
    sellAmount: BigNumber.from('0x' + bytes.substring(43, 66)),
  }
}

export function encodeOrder(order: Order): string {
  return (
    '0x' +
    order.userId.toHexString().slice(2).padStart(16, '0') +
    order.buyAmount.toHexString().slice(2).padStart(24, '0') +
    order.sellAmount.toHexString().slice(2).padStart(24, '0')
  )
}

export const queueStartElement =
  '0x0000000000000000000000000000000000000000000000000000000000000001'
export const queueLastElement = '0xffffffffffffffffffffffffffffffffffffffff000000000000000000000001'

export function getClearingPriceFromInitialOrder(order: Order): Order {
  return {
    userId: BigNumber.from(0),
    sellAmount: order.buyAmount,
    buyAmount: order.sellAmount,
  }
}

export function hasLowerClearingPrice(order1: Order, order2: Order): number {
  if (order1.buyAmount.mul(order2.sellAmount).lt(order2.buyAmount.mul(order1.sellAmount))) return -1
  if (order1.buyAmount.lt(order2.buyAmount)) return -1
  if (order1.buyAmount.mul(order2.sellAmount).eq(order2.buyAmount.mul(order1.sellAmount))) {
    if (order1.userId < order2.userId) return -1
  }
  return 1
}

export function findClearingPrice(sellOrders: Order[], initialAuctionOrder: Order): Order {
  sellOrders.forEach(function (order, index) {
    if (index > 1) {
      if (!hasLowerClearingPrice(sellOrders[index - 1], order)) {
        throw Error('The orders must be sorted')
      }
    }
  })
  let totalSellVolume = BigNumber.from(0)

  for (const order of sellOrders) {
    totalSellVolume = totalSellVolume.add(order.sellAmount)
    if (
      totalSellVolume.mul(order.buyAmount).div(order.sellAmount).gte(initialAuctionOrder.sellAmount)
    ) {
      const coveredBuyAmount = initialAuctionOrder.sellAmount.sub(
        totalSellVolume.sub(order.sellAmount).mul(order.buyAmount).div(order.sellAmount),
      )
      const sellAmountClearingOrder = coveredBuyAmount.mul(order.sellAmount).div(order.buyAmount)
      if (sellAmountClearingOrder.gt(BigNumber.from(0))) {
        return order
      } else {
        return {
          userId: BigNumber.from(0),
          buyAmount: initialAuctionOrder.sellAmount,
          sellAmount: totalSellVolume.sub(order.sellAmount),
        }
      }
    }
  }
  // otherwise, clearing price is initialAuctionOrder
  if (totalSellVolume.gt(initialAuctionOrder.buyAmount)) {
    return {
      userId: BigNumber.from(0),
      buyAmount: initialAuctionOrder.sellAmount,
      sellAmount: totalSellVolume,
    }
  } else {
    return {
      userId: BigNumber.from(0),
      buyAmount: initialAuctionOrder.sellAmount,
      sellAmount: initialAuctionOrder.buyAmount,
    }
  }
}

function sortOrdersByPriceRatio(allOrders: Order[]) {
  return allOrders.sort((orderA, orderB) => {
    const priceRatioA = BigNumber.from(orderA.buyAmount).div(orderA.sellAmount)
    const priceRatioB = BigNumber.from(orderB.buyAmount).div(orderB.sellAmount)

    if (priceRatioA.lt(priceRatioB)) {
      return -1
    } else if (priceRatioA.gt(priceRatioB)) {
      return 1
    } else {
      return 0
    }
  })
}

export function findPreviousOrder(allOrders: Order[], newOrder: Order): Order | null {
  // Sort allOrders by price ratio
  const sortedOrders = sortOrdersByPriceRatio(allOrders)

  // Initialize previousOrder as null and maxRatio as null
  let previousOrder: Order | null = null
  let maxRatio: { num: BigNumber; denom: BigNumber } | null = null

  // Iterate through sortedOrders
  for (const order of sortedOrders) {
    // Check if the current order has a smaller or equal price ratio than the new order
    const currentOrderLessThanOrEqualNewOrder = order.buyAmount
      .mul(newOrder.sellAmount)
      .lte(order.sellAmount.mul(newOrder.buyAmount))

    if (currentOrderLessThanOrEqualNewOrder) {
      // Update previousOrder with the current order if maxRatio is null or its price ratio is greater than maxRatio
      const currentOrderGreaterThanMaxRatio =
        !maxRatio || order.buyAmount.mul(maxRatio.denom).gt(order.sellAmount.mul(maxRatio.num))

      if (currentOrderGreaterThanMaxRatio) {
        previousOrder = order
        maxRatio = {
          num: order.buyAmount,
          denom: order.sellAmount,
        }
      }
    }
  }

  // Return previousOrder or null if not found
  return previousOrder
}
