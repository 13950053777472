import React from 'react'

import { GhostActionLink } from '../auction/Claimer'

import { getExplorerLink } from '@/utils'

export const GhostTransactionLink = ({ hash }) => (
  <GhostActionLink
    className="group space-x-3"
    href={getExplorerLink(hash, 'transaction')}
    target="_blank"
  >
    <span>View eth transaction</span>
    <svg fill="none" height="15" viewBox="0 0 14 15" width="14" xmlns="http://www.w3.org/2000/svg">
      <path
        className="fill-[#E0E0E0] group-hover:fill-black"
        d="M3.25 0.5H6.49292C6.90713 0.5 7.24292 0.835786 7.24292 1.25C7.24292 1.6297 6.96077 1.94349 6.59469 1.99315L6.49292 2H3.25C2.33183 2 1.57881 2.70711 1.5058 3.60647L1.5 3.75V11.25C1.5 12.1682 2.20711 12.9212 3.10647 12.9942L3.25 13H10.75C11.6682 13 12.4212 12.2929 12.4942 11.3935L12.5 11.25V7.5C12.5 7.08579 12.8358 6.75 13.25 6.75C13.6297 6.75 13.9435 7.03215 13.9932 7.39823L14 7.5V11.25C14 12.983 12.6435 14.3992 10.9344 14.4949L10.75 14.5H3.25C1.51697 14.5 0.100754 13.1435 0.00514483 11.4344L0 11.25V3.75C0 2.01697 1.35645 0.600754 3.06558 0.505145L3.25 0.5ZM13.25 0.5L13.2674 0.500202C13.2875 0.500665 13.3075 0.501924 13.3274 0.503981L13.25 0.5C13.2968 0.5 13.3427 0.504295 13.3872 0.512512C13.398 0.514576 13.4097 0.517015 13.4214 0.519735C13.4448 0.525171 13.4673 0.531579 13.4893 0.53898C13.4991 0.542303 13.5095 0.546028 13.5198 0.549989C13.5391 0.55745 13.5575 0.565445 13.5755 0.57414C13.5893 0.580723 13.6032 0.587983 13.617 0.595711C13.6353 0.606034 13.653 0.617047 13.6703 0.628763C13.6807 0.635685 13.6911 0.643176 13.7014 0.650968C13.7572 0.693178 13.8068 0.74276 13.8488 0.798381L13.7803 0.71967C13.8148 0.754111 13.8452 0.791068 13.8715 0.82995C13.8825 0.846342 13.8932 0.863492 13.9031 0.881084C13.9113 0.895376 13.9189 0.910009 13.9261 0.924829C13.9335 0.940269 13.9406 0.95638 13.9471 0.972774C13.9512 0.983142 13.955 0.993242 13.9585 1.0034C13.9656 1.02376 13.9721 1.04529 13.9776 1.06722C13.9814 1.0828 13.9847 1.09822 13.9876 1.1137C13.9908 1.13097 13.9935 1.14933 13.9956 1.16788C13.9975 1.18621 13.9988 1.20389 13.9995 1.22159C13.9998 1.23042 14 1.24019 14 1.25V4.75C14 5.41884 13.1908 5.75316 12.7187 5.27937L11.502 4.058L7.53033 8.03033C7.23744 8.32322 6.76256 8.32322 6.46967 8.03033C6.2034 7.76406 6.1792 7.3474 6.39705 7.05379L6.46967 6.96967L10.443 2.995L9.2314 1.77937C8.76025 1.3065 9.09517 0.5 9.7627 0.5H13.25Z"
      />
    </svg>
  </GhostActionLink>
)
