import { utils } from 'ethers'
import React, { useEffect, useState } from 'react'

import { useFormContext } from 'react-hook-form'

import { limitOrderType } from './ConfigureLimitOrder'

import { ONE_INCH_ROUTER_V5 } from '@/components/ProductCreate/SelectableTokens'
import { ApproveButton } from '@/components/buttons/ApproveButton'
import { useActiveWeb3React } from '@/hooks'
import { NETWORK_CONFIGS } from '@/utils'

export function addDays(date, days) {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const ApproveToken = () => {
  const { clearErrors, setError, trigger, watch } = useFormContext()
  const { chainId } = useActiveWeb3React()
  const [hasAllowance, setHasAllowance] = useState(false)
  const handleAllowanceChecked = (hasAllowance: boolean) => {
    setHasAllowance(hasAllowance)
  }

  const [bondToAuction, borrowToken, numberOfBonds, numberOfBorrowTokens, orderType] = watch([
    'bondToAuction',
    'borrowToken',
    'numberOfBonds',
    'numberOfBorrowTokens',
    'orderType',
  ])
  useEffect(() => {
    if (!hasAllowance) {
      setError('insufficientAllowance', {
        type: 'manual',
        message: 'The contract must have sufficient allowance to create a limit order.',
      })
    } else {
      clearErrors()
      trigger()
    }
  }, [clearErrors, setError, trigger, hasAllowance])

  const isSell = orderType === limitOrderType.Sell

  const makerTokenAddress = isSell ? bondToAuction?.id : borrowToken?.address
  const makingAmount = isSell ? numberOfBonds : numberOfBorrowTokens
  const makerTokenSymbol = isSell ? bondToAuction?.symbol : borrowToken?.symbol
  const OneInchRouterLink = () => (
    <a
      className="text-[#6CADFB] hover:underline"
      href={`${NETWORK_CONFIGS[chainId].etherscanUrl}/address/${ONE_INCH_ROUTER_V5[chainId]}`}
      rel="noreferrer"
      target="_blank"
    >
      Aggregation Router
    </a>
  )
  return (
    <>
      <ApproveButton
        amountToCheck={utils.parseUnits(makingAmount.toString(), bondToAuction.decimals)}
        contract={makerTokenAddress}
        hasInsufficientAllowanceComponent={
          <>
            To create a valid limit order, the <OneInchRouterLink /> must have the ability to spend
            the token you are selling. This will approve your {makerTokenSymbol} token with the
            maximum allowance. You will need to approve only one time per token.
          </>
        }
        onAllowanceChecked={handleAllowanceChecked}
        spender={ONE_INCH_ROUTER_V5[chainId]}
      >
        <>
          The tokens to sell are approved on the <OneInchRouterLink />.
        </>
      </ApproveButton>
    </>
  )
}
