import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { DocumentNode, useQuery } from '@apollo/client'
import { CaretDownIcon } from '@radix-ui/react-icons'

import { navItems } from '../sections'

import { AllBondsDocument } from '@/generated/graphql'
import { useActiveWeb3React } from '@/hooks'

const Wrapper = styled.nav`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 100%;
  max-width: 100%;
`

const NavItem = styled(NavLink)`
  align-items: center;
  color: #e0e0e0;
  opacity: 0.5;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  text-decoration: none;

  &:hover {
    color: #fafafa;
    opacity: 0.5;

    .fill {
      fill: #fafafa;
    }
  }

  &.active {
    color: #e0e0e0;
    background-color: transparent;
    opacity: 1;

    .fill {
      fill: #e0e0e0;
    }
  }
`

export const Mainmenu: React.FC = (props) => {
  const [navItem, setNavItem] = useState<{ preLoading: DocumentNode; title: string }>({
    preLoading: AllBondsDocument,
    title: '',
  })

  const { client } = useQuery(navItem.preLoading)
  const { account } = useActiveWeb3React()

  useEffect(() => {
    if (navItem.title === 'Portfolio') {
      client.query({
        query: navItem.preLoading,
        variables: { account: account?.toLowerCase() || '0x00' },
      })
    } else {
      client.query({
        query: navItem.preLoading,
      })
    }
  }, [account, client, navItem])

  return (
    <Wrapper {...props}>
      <ul className="menu menu-horizontal">
        {navItems.map((item, index) => {
          return (
            <li key={item.title + index.toString()}>
              <NavItem
                className={({ isActive }) => 'nav-link ' + (isActive && 'active')}
                onMouseOver={() => setNavItem({ preLoading: item.preLoading, title: item.title })}
                to={item.url}
              >
                {item.title}
                {item.children && <CaretDownIcon />}
              </NavItem>
              {item.children && (
                <ul className="z-10">
                  {item.children.map((link, linkI) => (
                    <li key={link.title + linkI.toString()}>
                      <NavItem to={link.url}>{link.title}</NavItem>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          )
        })}
      </ul>
    </Wrapper>
  )
}
