import React from 'react'

export const ArborIcon = () => (
  <svg
    height="32"
    id="Layer_1"
    version="1.1"
    viewBox="216.68 417.28 149.68 165.44"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M365.3,582.66c-3.7-7.79-6.43-16.67-8.3-27.04c-0.49-2.72-0.88-5.49-1.25-8.16 								c-1.05-7.58-2.05-14.73-5.3-21.47c-0.06-0.13-0.13-0.25-0.19-0.38c-4.89-9.86-13.21-17.04-23.5-20.23 								c-0.08-0.03-0.19-0.06-0.3-0.09c-1.76-0.5-6.79-1.63-10.21-2.1c0.5,0.4,1.02,0.83,1.56,1.28c0.09,0.07,0.17,0.14,0.26,0.21 								c1.33,1.18,2.78,2.46,4.19,3.92c0.7,0.73,1.42,1.49,2.15,2.26c0.72,0.78,1.39,1.64,2.1,2.48c1.46,1.65,2.73,3.55,4.09,5.4 								c0.03,0.04,0.05,0.08,0.08,0.12c1.28,1.86,2.43,3.84,3.63,5.78c1.05,2.08,2.19,4.11,3.15,6.18c0.88,2.11,1.81,4.15,2.58,6.17 								c0.69,2.05,1.36,4.03,1.99,5.91c0.57,1.89,0.97,3.72,1.42,5.39c0.43,1.67,0.83,3.19,1.03,4.58c0.24,1.37,0.45,2.57,0.62,3.56 								c0.34,1.97,0.54,3.09,0.54,3.09s-0.29-1.11-0.8-3.04c-0.25-0.97-0.56-2.14-0.91-3.48c-0.31-1.35-0.83-2.83-1.39-4.45 								c-0.57-1.61-1.12-3.37-1.83-5.18c-0.77-1.79-1.59-3.67-2.43-5.62c-0.92-1.91-1.99-3.82-3.01-5.79 								c-1.09-1.93-2.36-3.8-3.53-5.7c-1.34-1.8-2.61-3.64-4.01-5.34c-1.45-1.66-2.78-3.35-4.3-4.79c-0.74-0.73-1.43-1.49-2.17-2.16 								c-0.75-0.66-1.48-1.31-2.19-1.94c-1.42-1.25-2.86-2.32-4.18-3.3c-0.9-0.63-1.74-1.19-2.54-1.72 								c-7.61,25.73-6.23,45.31,4.18,58.24C330.5,582.58,357.08,582.98,365.3,582.66z"
                    style={{ fill: '#52C48D' }}
                  ></path>
                </g>
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M354.5,417.28c-4.89,7.1-11.22,13.9-19.27,20.71c-2.11,1.79-4.31,3.5-6.44,5.16 							c-6.04,4.7-11.73,9.14-15.94,15.33c-0.08,0.12-0.15,0.24-0.23,0.36c-6.09,9.16-8.15,19.96-5.78,30.47 							c0.02,0.08,0.05,0.19,0.07,0.3c0.45,1.77,1.98,6.7,3.28,9.89c0.1-0.64,0.21-1.3,0.33-1.99c0.02-0.11,0.03-0.22,0.05-0.33 							c0.36-1.75,0.74-3.64,1.3-5.59c0.29-0.97,0.58-1.97,0.88-2.99c0.32-1.01,0.73-2.02,1.09-3.06c0.7-2.09,1.71-4.14,2.63-6.24 							c0.02-0.04,0.04-0.08,0.06-0.13c0.97-2.04,2.11-4.02,3.19-6.03c1.27-1.95,2.46-3.95,3.78-5.81c1.39-1.82,2.69-3.64,4.05-5.32 							c1.43-1.62,2.81-3.19,4.12-4.68c1.35-1.44,2.74-2.7,3.96-3.92c1.23-1.21,2.35-2.31,3.45-3.18c1.07-0.9,2-1.68,2.77-2.32 							c1.53-1.28,2.41-2.02,2.41-2.02s-0.81,0.8-2.24,2.21c-0.71,0.7-1.57,1.55-2.56,2.53c-1.02,0.94-2.04,2.13-3.15,3.43 							c-1.1,1.3-2.36,2.65-3.57,4.18c-1.16,1.56-2.38,3.21-3.65,4.92c-1.2,1.75-2.31,3.64-3.51,5.5c-1.13,1.91-2.11,3.94-3.18,5.91 							c-0.89,2.06-1.85,4.08-2.62,6.14c-0.71,2.08-1.51,4.08-2,6.11c-0.27,1.01-0.57,1.98-0.79,2.96c-0.2,0.98-0.4,1.93-0.59,2.86 							c-0.37,1.86-0.58,3.64-0.77,5.27c-0.09,1.1-0.17,2.1-0.21,3.06c26.08-6.27,42.35-17.26,48.35-32.73 							C371.83,447.46,358.89,424.24,354.5,417.28z"
                  style={{ fill: '#52C48D' }}
                ></path>
              </g>
            </g>
            <g>
              <path
                d="M216.68,509.32c8.6,0.69,17.65,2.77,27.57,6.33c2.6,0.93,5.19,1.98,7.69,3 						c7.09,2.88,13.78,5.59,21.24,6.14c0.14,0.01,0.28,0.01,0.42,0.02c10.98,0.69,21.37-2.92,29.27-10.23 						c0.06-0.06,0.14-0.14,0.22-0.21c1.31-1.27,4.81-5.07,6.92-7.79c-0.6,0.23-1.23,0.47-1.88,0.71c-0.11,0.04-0.21,0.08-0.32,0.12 						c-1.69,0.56-3.52,1.18-5.49,1.67c-0.99,0.24-2,0.48-3.03,0.74c-1.04,0.23-2.11,0.38-3.2,0.58c-2.16,0.44-4.44,0.59-6.72,0.84 						c-0.05,0-0.1,0-0.14,0.01c-2.25,0.18-4.54,0.18-6.81,0.25c-2.33-0.13-4.65-0.15-6.92-0.36c-2.27-0.29-4.5-0.5-6.63-0.85 						c-2.12-0.43-4.17-0.84-6.11-1.23c-1.92-0.45-3.71-1.02-5.37-1.47c-1.66-0.46-3.18-0.88-4.48-1.4c-1.31-0.48-2.45-0.9-3.39-1.24 						c-1.88-0.69-2.95-1.08-2.95-1.08s1.1,0.3,3.03,0.83c0.96,0.26,2.13,0.58,3.47,0.95c1.33,0.41,2.87,0.7,4.55,1.02 						c1.68,0.31,3.48,0.72,5.41,1c1.93,0.22,3.97,0.46,6.08,0.7c2.11,0.16,4.31,0.19,6.52,0.29c2.22,0.02,4.46-0.14,6.7-0.2 						c2.23-0.26,4.46-0.44,6.63-0.8c2.16-0.42,4.29-0.73,6.29-1.33c1-0.27,2-0.5,2.95-0.79c0.95-0.32,1.87-0.62,2.77-0.92 						c1.8-0.61,3.44-1.32,4.95-1.96c1-0.47,1.9-0.91,2.76-1.34c-18.47-19.45-36.12-28.05-52.52-25.5 						C234.15,479.23,220.51,502.04,216.68,509.32z"
                style={{ fill: '#FFF' }}
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
