import React from 'react'

import dayjs from 'dayjs'
import { useFormContext } from 'react-hook-form'
import { useBalance } from 'wagmi'

import { addDays } from './ApproveToken'
import { BondSelector } from '../../ProductCreate/selectors/CollateralTokenSelector'
import { BuySellControl } from '../BuySellControl'

import BorrowTokenSelector from '@/components/ProductCreate/selectors/BorrowTokenSelector'
import TooltipElement from '@/components/common/Tooltip'
import { useActiveWeb3React } from '@/hooks'
import { AllBondsList } from '@/pages/Orderbook'

export const limitOrderType = {
  Buy: 'Buy',
  Sell: 'Sell',
}

export const ConfigureLimitOrder = () => {
  const { register, watch } = useFormContext()
  const { account } = useActiveWeb3React()
  const [borrowToken, orderType, bondToAuction] = watch([
    'borrowToken',
    'orderType',
    'bondToAuction',
  ])

  const { data: bondBalance } = useBalance({
    address: account,
    token: bondToAuction?.id,
    formatUnits: bondToAuction?.decimals,
  })
  const { data: borrowTokenBalance } = useBalance({
    address: account,
    token: borrowToken?.address,
    formatUnits: borrowToken?.decimals,
  })

  const isSell = orderType === limitOrderType.Sell

  return (
    <>
      <BuySellControl />
      <div className="form-control w-full">
        <div className="flex items-center"></div>
        <label className="label">
          <TooltipElement
            left={<span className="label-text">Bond</span>}
            tip={`Bond that you will ${isSell ? 'sell' : 'buy'}.`}
          />
        </label>
        {isSell ? <BondSelector /> : <AllBondsList />}
      </div>

      <div className="form-control w-full">
        <label className="label">
          <TooltipElement
            left={<span className="label-text">Number of bonds</span>}
            tip={`Number of bonds you will ${isSell ? 'sell' : 'buy'}.`}
          />
        </label>
        <input
          className="input-bordered input w-full"
          inputMode="numeric"
          onWheel={({ target }) => (target as HTMLInputElement).blur()}
          placeholder="0"
          type="number"
          {...register('numberOfBonds', {
            required: 'Some bonds must be traded',
            validate: {
              greaterThanZero: (value) => value > 0 || 'Some bonds must be traded',
              lessThanBalance: (value) => {
                if (orderType === limitOrderType.Buy) return true
                return (
                  value <= Number(bondBalance?.formatted) ||
                  `The current wallet's balance (${bondBalance?.formatted}) does not cover the amount of bonds to be traded`
                )
              },
            },
          })}
        />
      </div>

      <div className="form-control w-full">
        <label className="label">
          <TooltipElement
            left={<span className="label-text">Token</span>}
            tip={`Token that you will ${isSell ? 'receive' : 'use to purchase the bonds'}. `}
          />
        </label>
        <BorrowTokenSelector />
      </div>
      <div className="form-control w-full">
        <label className="label">
          <TooltipElement
            left={
              <span className="label-text">{`Total Order ${
                borrowToken?.name ?? 'Token'
              } Amount`}</span>
            }
            tip={`Number of tokens that you will ${
              isSell ? 'receive' : 'exchange'
            } for the entire order.`}
          />
        </label>
        <input
          className="input-bordered input w-full"
          inputMode="numeric"
          onWheel={({ target }) => (target as HTMLInputElement).blur()}
          placeholder="0"
          step="0.001"
          type="number"
          {...register('numberOfBorrowTokens', {
            required: 'Some tokens must be traded',
            validate: {
              greaterThanZero: (value) => value > 0 || 'Some tokens must be traded',
              lessThanBalance: (value) => {
                if (orderType === limitOrderType.Sell) return true
                if (!borrowToken?.address) return true
                return (
                  value <= Number(borrowTokenBalance?.formatted) ||
                  `The current wallet's balance (${borrowTokenBalance?.formatted}) does not cover the amount of tokens to be traded`
                )
              },
            },
          })}
        />
        <div className="form-control mt-4 w-full">
          <label className="label">
            <TooltipElement
              left={<span className="label-text">Order expiration date</span>}
              tip="Date the order must be fulfilled by. If not fulfilled, the order will be expired and removed from the orderbook. This is UTC time."
            />
          </label>
          <input
            className="input-bordered input w-full"
            defaultValue={`${addDays(new Date(), 3).toISOString().substring(0, 10)} 23:59`}
            min={dayjs(new Date()).utc().add(0, 'day').format('YYYY-MM-DD HH:mm')}
            placeholder="MM/DD/YYYY HH:mm"
            type="datetime-local"
            {...register('expiry', {
              required: 'Order expiration date',
              validate: {
                validDate: (expiry) =>
                  dayjs(expiry).isValid() || 'The expiration date must be in the future',
                afterNow: (expiry) =>
                  dayjs(expiry).diff(new Date()) > 0 || 'The expiration date must be in the future',
                before10Years: (expiry) =>
                  dayjs(new Date()).add(10, 'years').isAfter(expiry) ||
                  'The expiration date must be within 10 years',
              },
            })}
          />
        </div>
      </div>
    </>
  )
}
