import React from 'react'

export const FOXIcon = () => (
  <svg
    id="Layer_1"
    style={{
      height: '32px',
      background: 'white',
      borderRadius: '5px',
      padding: '5px',
    }}
    version="1.1"
    viewBox="0 0 55.7 61"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g id="Mock-Ups">
      <g id="Landing-Page" transform="translate(-769.000000, -76.000000)">
        <g id="Nav" transform="translate(79.000000, 69.000000)">
          <g id="SS_horizontal_White" transform="translate(690.056800, 7.581451)">
            <g id="Mark" transform="translate(0.434395, 0.356808)">
              <path
                d="M51.2,4.2l-2.7,16.2L38.9,9.1L51.2,4.2z M48.5,27.3l2.4,8.9l-18.4,5.1L48.5,27.3z M8.5,22.9
							L18.4,10h17.1l10.9,12.9H8.5z M45.2,26L27.4,41.6L9.2,26H45.2z M15.1,9.1L6.3,20.6L3.6,4.2L15.1,9.1z M22.1,41.3L3.5,36.2
							l2.5-8.7L22.1,41.3z M25.5,45.5L22,49.3c-3-2.8-6.2-5.3-9.7-7.5L25.5,45.5z M42.5,41.8c-3.5,2.2-6.7,4.7-9.7,7.5l-3.6-3.9
							L42.5,41.8z M55.2-0.9L35.9,6.8h-18L-0.5-0.9l4.2,25.3l-4,13.7l10.5,6.3c5,3,9.4,6.7,13.2,11l4.1,4.7l4.3-4.9
							c3.7-4.2,8-7.8,12.7-10.8L54.7,38L51,24.4L55.2-0.9L55.2-0.9z"
                id="Fill-16"
                style={{ fill: '#131D27' }}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
