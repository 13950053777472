import React from 'react'

import { Summary } from './Summary'
import { BondActionSteps } from '../BondActionSteps'
import { FormSteps } from '../FormSteps'
import { StepOne } from '../StepOne'
import { StepTwo } from '../convert/StepTwo'

const SetupSimpleProduct = () => {
  const midComponents = [<StepOne key={0} />, <StepTwo key={1} />]
  const steps = ['Bond', 'Collateral', 'Confirm']

  return (
    <FormSteps
      ActionSteps={BondActionSteps}
      Summary={Summary}
      color="purple"
      convertible={false}
      midComponents={midComponents}
      steps={steps}
      title="Simple Bond Creation"
    />
  )
}

export default SetupSimpleProduct
