import { utils } from 'ethers'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'

import { DoubleArrowRightIcon } from '@radix-ui/react-icons'
import { FormProvider, useForm } from 'react-hook-form'
import { useToken } from 'wagmi'

import { ReactComponent as DividerIcon } from '../../assets/svg/divider.svg'
import { ReactComponent as WalletIcon } from '../../assets/svg/wallet.svg'
import Table from '../../components/auctions/Table'
import { useActiveWeb3React } from '../../hooks'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useSetNoDefaultNetworkId } from '../../state/orderPlacement/hooks'
import {
  AllButton,
  BuyLimitOrdersButtonOutline,
  MyLimitOrdersButtonOutline,
  SellLimitOrdersButtonOutline,
  TheirLimitOrdersButtonOutline,
} from '../Auction'
import { TABLE_FILTERS } from '../Portfolio'

import FillOrderModal from '@/components/LimitOrderCreate/FillOrderModal'
import { limitOrderType } from '@/components/LimitOrderCreate/order/ConfigureLimitOrder'
import { BondTokenDetails } from '@/components/ProductCreate/BondTokenDetails'
import { BorrowTokens } from '@/components/ProductCreate/SelectableTokens'
import { Selector } from '@/components/ProductCreate/selectors/BorrowTokenSelector'
import { NoBondFound } from '@/components/ProductCreate/selectors/CollateralTokenSelector'
import TermsModal from '@/components/TermsModal'
import { ActionButton } from '@/components/auction/Claimer'
import { ActiveStatusPill } from '@/components/auction/OrderbookTable'
import { ErrorBoundaryWithFallback } from '@/components/common/ErrorAndReload'
import { calculateInterestRate } from '@/components/form/InterestRateInputPanel'
import { useBond, useBonds } from '@/hooks/useBond'
import { useBondsPortfolio } from '@/hooks/useBondsPortfolio'
import useLocalStorage from '@/hooks/useLocalStorage'
import { useOrderbookPair } from '@/hooks/useOrderbook'
import { currentTimeInUTC } from '@/utils/tools'

export const UseAllBonds = () => {
  const { data: dataPortfolio } = useBondsPortfolio()

  const portfolioData = {}

  dataPortfolio?.forEach((bond) => {
    portfolioData[bond?.id] = bond
  })

  const { data: bondData, loading } = useBonds()

  const activeBondsAndAllInPortfolio = bondData?.map((bond) => {
    if (bond.id in portfolioData) {
      const tokenBalances = portfolioData[bond.id].tokenBalances
      return { ...bond, tokenBalances }
    }
    if (bond.state === 'active') {
      return { ...bond }
    }
  })

  function filter(bond) {
    if (bond) {
      return bond
    }
  }

  const timeOrderedBonds = activeBondsAndAllInPortfolio?.filter(filter).reverse()

  return { bondData, timeOrderedBonds, loading }
}

export const AllBondsList = () => {
  const { loading, timeOrderedBonds } = UseAllBonds()

  if (!timeOrderedBonds?.length && !loading) {
    return (
      <Selector OptionEl={NoBondFound} disabled name="bondToAuction" options={timeOrderedBonds} />
    )
  } else if (loading) {
    return (
      <Selector OptionEl={NoBondFound} disabled name="bondToAuction" options={timeOrderedBonds} />
    )
  }
  return (
    <Selector
      OptionEl={BondTokenDetails}
      name="bondToAuction"
      options={timeOrderedBonds}
      selectFirst
    />
  )
}

const GlobalStyle = createGlobalStyle`
  .siteHeader {
    background: #214B1F !important;
  }
`

const Orderbook = () => {
  const methods = useForm({
    mode: 'onChange',
  })
  const { account, signer } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const navigate = useNavigate()
  const [tableFilter, setTableFilter] = useState(TABLE_FILTERS.ALL)
  const [orderToFill, setOrderToFill] = useState(null)
  const [showTerms, setShowTerms] = useState(false)
  const [termsAccepted, setTermsAccepted] = useLocalStorage('acceptedTerms', false)

  const { chainId } = useActiveWeb3React()
  const [bondToAuction] = methods.watch(['bondToAuction'])

  useEffect(() => {
    if (termsAccepted) return
    else setShowTerms(true)
  }, [termsAccepted])

  const { loading } = useBonds()

  const {
    data: { asks: limitOrderAsks, bids: limitOrderBids },
    loading: loadingOrderbook,
  } = useOrderbookPair(BorrowTokens[chainId][0].address, bondToAuction?.id)
  const { data: borrowToken } = useToken({ address: BorrowTokens[chainId][0].address })
  const { data: bondToken } = useToken({ address: bondToAuction?.id })

  const bondId = bondToAuction?.id
  const { data: selectedBondData } = useBond(bondId)

  const columns = useMemo(() => {
    return [
      {
        Header: 'Transaction Type',
        accessor: 'orderType',
        tooltip:
          '"Sell Bonds" means you will be selling your bonds from another party. "Buy Bonds" means you will be buying bonds from another party.',
        style: { height: '100%', justifyContent: 'center' },
        filter: 'searchInTags',
        disableSortBy: true,
        Cell: (props) => {
          return (
            <ActiveStatusPill
              title={props.value === limitOrderType.Sell ? 'Buy Bonds' : 'Sell Bonds'}
            />
          )
        },
      },
      {
        Header: 'Price per Bond',
        accessor: 'pricePerBond',
        tooltip: 'Price of the order.',
        filter: 'searchInTags',
        Cell: (props) => {
          const { bondToken, borrowToken } = props?.row?.original || {}
          if (!borrowToken || !bondToken) return null
          const units = `${borrowToken?.symbol} per Bond`
          return `${props.value} ${units}`
        },
      },
      {
        Header: 'YTM',
        id: 'ytm',
        accessor: 'ytm',
        tooltip: 'Yield to Maturity of the bond at the order price.',
        filter: 'searchInTags',
      },
      {
        Header: 'Order Amount',
        accessor: 'remainingMakerAmount',
        tooltip:
          'Amount of tokens needed to fulfill the order. If you fill this order, you will receive this amount and type of tokens. Orders can be partially filled.',
        filter: 'searchInTags',
        Cell: (props) => {
          const { makerAsset, orderType } = props?.row?.original || {}
          const { data: token } = useToken({ address: makerAsset })
          const takerTokenSymbol = orderType === limitOrderType.Buy ? token?.symbol : 'BOND'
          return `${utils.formatUnits(props.value, token?.decimals)} ${takerTokenSymbol}`
        },
      },
      {
        Header: 'Price',
        accessor: 'takingAmount',
        tooltip:
          'Amount of tokens the maker of this order is exchanging for the total order amount. If you fill this order, you will pay this amount and type of tokens in exchange for the order amount.',
        filter: 'searchInTags',
        Cell: (props) => {
          const { orderType, takerAsset } = props?.row?.original || {}
          const { data: token } = useToken({ address: takerAsset })
          const takerTokenSymbol = orderType === limitOrderType.Buy ? 'BOND' : token?.symbol
          return `${utils.formatUnits(props.value, token?.decimals)} ${takerTokenSymbol}`
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true,
        tooltip:
          'Execute a transaction to fill this limit order, cancel the order, or approve a token.',
      },
    ]
  }, [])

  const limitOrders = useMemo(() => {
    return (
      (!loadingOrderbook &&
        limitOrderAsks
          .concat(limitOrderBids)
          ?.map(({ data, remainingMakerAmount, signature }, index, array) => {
            const priceInMakerAsset = utils.formatUnits(
              utils.parseUnits(data.takingAmount, borrowToken?.decimals).div(data.makingAmount),
              borrowToken?.decimals,
            )

            const type = account && account.toLowerCase() === data.maker ? 'my' : 'their'
            const orderType =
              data.makerAsset.toLowerCase() === bondToAuction?.id?.toLowerCase()
                ? limitOrderType.Sell
                : limitOrderType.Buy

            const isSell = orderType === limitOrderType.Sell
            const bondAmount =
              orderType === limitOrderType.Sell ? data.makingAmount : data.takingAmount
            const borrowAmount =
              orderType === limitOrderType.Sell ? data.takingAmount : data.makingAmount

            const pricePerBond = Number(
              utils.formatUnits(
                utils.parseUnits(borrowAmount, borrowToken?.decimals).div(bondAmount),
                borrowToken?.decimals,
              ),
            )

            const maturityDate = selectedBondData?.maturityDate
            const ytm = calculateInterestRate({
              price: pricePerBond,
              maturityDate: maturityDate,
              startDate: currentTimeInUTC() / 1000,
            })
            const actions = (
              <button
                className="btn-primary btn-sm btn space-x-2 rounded-md bg-transparent !text-xxs font-normal"
                onClick={() => navigate(`/bonds/${isSell ? data.makerAsset : data.takerAsset}`)}
              >
                <span>View Bond</span>
                <span>
                  <DoubleArrowRightIcon />
                </span>
              </button>
            )

            return {
              ...data,
              borrowToken,
              bondToken,
              priceInMakerAsset,
              orderType,
              type,
              actions,
              search: JSON.stringify(array[index]),
              remainingMakerAmount,
              pricePerBond,
              ytm,
              url: `/bonds/${bondToken?.address}`,
              onclick: () => {
                const fillableAmountInMakerAsset = Number(
                  utils.formatUnits(remainingMakerAmount, borrowToken?.decimals),
                )
                setOrderToFill({
                  limitOrder: data,
                  signature,
                  fillableAmountInMakerAsset,
                  isSell,
                  pricePerBond,
                })
              },
            }
          })) ||
      []
    ).filter(({ orderType, type }) =>
      tableFilter ? tableFilter === type || tableFilter === orderType : true,
    )
  }, [
    loadingOrderbook,
    limitOrderAsks,
    limitOrderBids,
    borrowToken,
    account,
    bondToAuction?.id,
    selectedBondData?.maturityDate,
    bondToken,
    tableFilter,
    navigate,
  ])
  const emptyActionText = 'Create an Order'
  const emptyActionClick = account ? () => navigate('/orderbook/create') : toggleWalletModal
  const emptyDescription = 'There are no orders to display.'
  const emptyLogo = <WalletIcon height={49.5} width={51} />

  useSetNoDefaultNetworkId()

  return (
    <>
      <FormProvider {...methods}>
        <GlobalStyle />
        <ErrorBoundaryWithFallback>
          <TermsModal
            abortModal={() => setShowTerms(false)}
            acceptTerms={() => {
              setShowTerms(false)
              setTermsAccepted(true)
            }}
            close={() => setShowTerms(false)}
            isOpen={showTerms}
          />
          <FillOrderModal
            abortModal={() => setOrderToFill(null)}
            acceptTerms={() => {
              setOrderToFill(null)
            }}
            close={() => setOrderToFill(null)}
            data={orderToFill}
            isOpen={orderToFill}
          />

          <Table
            columns={columns}
            data={limitOrders}
            emptyActionClick={emptyActionClick}
            emptyActionText={emptyActionText}
            emptyDescription={emptyDescription}
            emptyLogo={emptyLogo}
            header={
              <div className="bg-[#1F2123]] form-control w-full sm:w-96">
                <AllBondsList />
              </div>
            }
            legendIcons={
              <>
                <AllButton
                  active={tableFilter === TABLE_FILTERS.ALL}
                  onClick={() => setTableFilter(TABLE_FILTERS.ALL)}
                />
                <DividerIcon />
                <MyLimitOrdersButtonOutline
                  active={tableFilter === TABLE_FILTERS.MY}
                  onClick={() => setTableFilter(TABLE_FILTERS.MY)}
                />
                <TheirLimitOrdersButtonOutline
                  active={tableFilter === TABLE_FILTERS.THEIR}
                  onClick={() => setTableFilter(TABLE_FILTERS.THEIR)}
                />
                <BuyLimitOrdersButtonOutline
                  active={tableFilter === TABLE_FILTERS.SELL}
                  onClick={() => setTableFilter(TABLE_FILTERS.SELL)}
                />
                <SellLimitOrdersButtonOutline
                  active={tableFilter === TABLE_FILTERS.BUY}
                  onClick={() => setTableFilter(TABLE_FILTERS.BUY)}
                />
              </>
            }
            loading={loading || loadingOrderbook}
            name="bonds"
            sortBy={[{ id: 'pricePerBond', desc: false }]}
            tableDecorations={
              limitOrders.length > 0 && (
                <div className="mb-4 grid w-full justify-items-end pr-2">
                  <thead>
                    <tr>
                      <th>
                        <ActionButton
                          className="text-right"
                          onClick={() => navigate('/orderbook/create')}
                        >
                          Create Order
                        </ActionButton>
                      </th>
                    </tr>
                  </thead>
                </div>
              )
            }
            title="Limit Orders"
          />
        </ErrorBoundaryWithFallback>
      </FormProvider>
    </>
  )
}

export default Orderbook
