import { DocumentNode } from 'graphql'

import { isGoerli } from '@/connectors'
import { AccountDetailsDocument, AllAuctionsDocument, AllBondsDocument } from '@/generated/graphql'

const BOND_NAV_ITEM = {
  title: 'Bonds',
  url: '/bonds',
  children: [
    {
      title: 'Create bond',
      url: '/bonds/create',
    },
  ],
  preLoading: AllBondsDocument as DocumentNode,
}

const ORDERBOOK_NAV_ITEM = {
  title: 'Orderbook',
  url: '/orderbook',
  children: [
    {
      title: 'Buy Bonds',
      url: '/orderbook/create?orderType=Buy',
    },
    {
      title: 'Sell Bonds',
      url: '/orderbook/create?orderType=Sell',
    },
  ],
  preLoading: AllBondsDocument as DocumentNode,
}

const AUCTION_NAV_ITEM = {
  title: 'Auctions',
  url: '/auctions',
  children: [
    {
      title: 'Create auction',
      url: '/auctions/create',
    },
  ],
  preLoading: AllAuctionsDocument as DocumentNode,
}

const PORTFOLIO_NAV_ITEM = {
  title: 'Portfolio',
  url: '/portfolio',
  children: null,
  preLoading: AccountDetailsDocument as DocumentNode,
}

export const navItems = isGoerli
  ? [BOND_NAV_ITEM, AUCTION_NAV_ITEM, PORTFOLIO_NAV_ITEM]
  : [BOND_NAV_ITEM, ORDERBOOK_NAV_ITEM, AUCTION_NAV_ITEM, PORTFOLIO_NAV_ITEM]
