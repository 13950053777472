import { useEffect, useState } from 'react'

import { getContractAddress } from '@ethersproject/address'

import { useActiveWeb3React } from '.'
import { useBondFactoryContract } from './useContract'

export type ScheduledBond = {
  name: string
  symbol: string
  maturityDate: number
  paymentTokenAddress: string
  collateralTokenAddress: string
  collateralTokenAmount: string
  convertibleTokenAmount: string
  maxSupply: string
}

export const setLocalStorageScheduledBond = (bond: ScheduledBond) => {
  window.localStorage.setItem('scheduledBond', JSON.stringify(bond))
}

export const getLocalStorageScheduledBond = (): ScheduledBond => {
  const bond = window.localStorage.getItem('scheduledBond')
  return bond ? JSON.parse(bond) : null
}

export const useScheduledBond = () => {
  const arborBondFactory = useBondFactoryContract()
  const { provider } = useActiveWeb3React()
  const [scheduledBond, setScheduledBond] = useState<any>()
  const [scheduledBondLoading, setScheduledBondLoading] = useState<boolean>(true)

  useEffect(() => {
    setScheduledBondLoading(true)
    const scheduledBond = getLocalStorageScheduledBond()

    if (!scheduledBond || !arborBondFactory) return

    const getNextBondAddress = async () => {
      const nonce = await provider
        .getTransactionCount(arborBondFactory.address)
        .catch((e) => console.log({ e }))

      if (!nonce) return
      return getContractAddress({ from: arborBondFactory.address, nonce })
    }

    const setBondFromStorage = async () => {
      const bondAddress = await getNextBondAddress()
      const {
        collateralTokenAddress,
        collateralTokenAmount,
        convertibleTokenAmount,
        maturityDate,
        maxSupply,
        name,
        paymentTokenAddress,
        symbol,
      } = getLocalStorageScheduledBond()

      setScheduledBond({
        id: bondAddress,
        name,
        symbol,
        maturityDate: maturityDate.toString(),
        //WILL NEED TO CHANGE IF PAYMENT TOKEN ANYTHING OTHER THAN USDC
        paymentToken: { id: paymentTokenAddress, decimals: 6, symbol: 'USDC' },
        paymentTokenAddress: paymentTokenAddress,
        collateralTokenAddress,
        collateralTokenAmount: collateralTokenAmount,
        convertibleTokenAmount: convertibleTokenAmount,
        tokenBalances: [{ amount: maxSupply }],
        decimals: 6,
        maxSupply,
      })
      setScheduledBondLoading(false)
    }
    setBondFromStorage()
  }, [arborBondFactory, provider])
  return { scheduledBond, scheduledBondLoading }
}
