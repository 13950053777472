import useSWR from 'swr'

import { useActiveWeb3React } from '.'
import { LimitOrderAll } from '../pages/BondDetail/OrderbookApi'
import { getLogger } from '../utils/logger'

import { isGoerli } from '@/connectors'
const logger = getLogger('useOrderbook')

const fetcher = (url: string) => fetch(url).then((r) => r.json())
export const ONE_INCH_API: { [key: number]: string } = {
  1: 'https://limit-orders.1inch.io/v3.0/1/',
}

export const ONE_INCH_ENDPOINT: { [key: number]: { [key: string]: string } } = {
  1: {
    pair: 'limit-order/all',
    order: 'limit-order',
  },
}

export const useOrderbookPair = (
  takerAsset?: string,
  makerAsset?: string,
): { data: { asks: LimitOrderAll[] | null; bids: LimitOrderAll[] | null }; loading: boolean } => {
  const { chainId } = useActiveWeb3React()
  const { data: makerTaker } = useSWR(
    takerAsset && makerAsset && chainId && !isGoerli && ONE_INCH_ENDPOINT[chainId]['pair']
      ? `${ONE_INCH_API[chainId]}${ONE_INCH_ENDPOINT[chainId]['pair']}?makerAsset=${makerAsset}&takerAsset=${takerAsset}`
      : null,
    fetcher,
    { refreshInterval: 60 * 1000 },
  )
  const { data: takerMaker } = useSWR(
    takerAsset && makerAsset && chainId && !isGoerli && ONE_INCH_ENDPOINT[chainId]['pair']
      ? `${ONE_INCH_API[chainId]}${ONE_INCH_ENDPOINT[chainId]['pair']}?takerAsset=${makerAsset}&makerAsset=${takerAsset}`
      : null,
    fetcher,
    { refreshInterval: 60 * 1000 },
  )
  // 1 Inch API returns `statusCode: 500` and an error string
  if (makerTaker?.statusCode === 500 || takerMaker?.statusCode === 500) {
    logger.error('Error getting useOrderbook info:', makerTaker?.message || takerMaker?.message)
    return {
      data: { asks: null, bids: null },
      loading: true,
    }
  }

  return {
    data: { asks: makerTaker, bids: takerMaker },
    loading: !makerTaker || !takerMaker,
  }
}
