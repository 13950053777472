import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { LimitOrderData } from '@1inch/limit-order-protocol-utils'
import { parseUnits } from '@ethersproject/units'
import dayjs from 'dayjs'
import { useFormContext } from 'react-hook-form'

import { limitOrderType } from './ConfigureLimitOrder'

import TermsModal from '@/components/TermsModal'
import { ActionButton } from '@/components/auction/Claimer'
import { ErrorBox } from '@/components/auction/OrderPlacement'
import { useActiveWeb3React } from '@/hooks'
import useLocalStorage from '@/hooks/useLocalStorage'
import { createLimitOrder } from '@/pages/BondDetail/OrderbookApi'

export function addDays(date, days) {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const SignOrder = () => {
  const [signed, setSigned] = useState(false)
  const [error, setError] = useState(null)
  const [showTerms, setShowTerms] = useState(false)
  const [termsAccepted, setTermsAccepted] = useLocalStorage('acceptedTerms', false)
  const navigate = useNavigate()

  const { watch } = useFormContext()
  const { account, chainId, signer } = useActiveWeb3React()
  const [bondToAuction, borrowToken, numberOfBonds, numberOfBorrowTokens, expiry, orderType] =
    watch([
      'bondToAuction',
      'borrowToken',
      'numberOfBonds',
      'numberOfBorrowTokens',
      'expiry',
      'orderType',
    ])

  useEffect(() => {
    if (termsAccepted) return
    else setShowTerms(true)
  }, [termsAccepted])

  const limitOrder = async () => {
    if (!signer || !chainId) {
      return
    }
    const isSell = orderType === limitOrderType.Sell

    const orderData: LimitOrderData = {
      makerAssetAddress: isSell ? bondToAuction.id : borrowToken.address,
      takerAssetAddress: isSell ? borrowToken.address : bondToAuction.id,
      makingAmount: isSell
        ? (parseUnits(numberOfBonds.toString(), bondToAuction.decimals).toString() as any)
        : (parseUnits(numberOfBorrowTokens.toString(), borrowToken.decimals).toString() as any),
      takingAmount: isSell
        ? (parseUnits(numberOfBorrowTokens.toString(), borrowToken.decimals).toString() as any)
        : (parseUnits(numberOfBonds.toString(), bondToAuction.decimals).toString() as any),
      makerAddress: account,
    }

    return await createLimitOrder(orderData, {
      signer,
      expiration: Math.floor(dayjs(expiry).unix()).toString() as any,
      chainId,
    })
  }
  return (
    <>
      <div className="form-control w-full">
        <span className="mb-4">
          Interact with 1Inch limit order protocol by transmitting a signature with the order
          details. This is sent to the 1Inch server and will show up on the{' '}
          <a className="text-[#6CADFB] hover:underline" href="/orderbook">
            orderbook list
          </a>
          .
        </span>
        {signed ? (
          <ActionButton
            onClick={() => {
              navigate('/orderbook')
            }}
          >
            View orderbook page
          </ActionButton>
        ) : termsAccepted ? (
          <>
            <ActionButton
              className="btn mt-2"
              onClick={async () => {
                try {
                  const limitOrderCreationSucceeded = await limitOrder()
                  if (!limitOrderCreationSucceeded) {
                    throw new Error('Limit order creation failed')
                  }
                  setSigned(true)
                } catch (e) {
                  setError('There was a problem signing the order.')
                }
              }}
            >
              Sign Order
            </ActionButton>
            <ErrorBox error={error} errorText={error} />
          </>
        ) : (
          <TermsModal
            abortModal={() => setShowTerms(false)}
            acceptTerms={() => {
              setShowTerms(false)
              setTermsAccepted(true)
            }}
            close={() => setShowTerms(false)}
            isOpen={showTerms}
          />
        )}
      </div>
    </>
  )
}
