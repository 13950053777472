import React from 'react'
import { useNavigate } from 'react-router-dom'

import { formatUnits } from '@ethersproject/units'
import dayjs from 'dayjs'
import { round } from 'lodash'
import { useContractRead } from 'wagmi'

import { useBond } from './useBond'
import { useTokenPrice } from './useTokenPrice'
import { Props as ExtraDetailsItemProps } from '../components/auction/ExtraDetailsItem'
import TokenLink from '../components/token/TokenLink'

import BOND_ABI from '@/constants/abis/bond.json'
import { Bond } from '@/generated/graphql'
import { useAuctions } from '@/hooks/useAuction'

export const WADDecimals = 18
export const paymentTokenPrice = 1

export const getValuePerBond = (
  bond: Pick<Bond, 'collateralToken' | 'paymentToken'>,
  value: number,
) => {
  return bond
    ? Number(
        formatUnits(
          value,
          WADDecimals + bond.collateralToken.decimals - bond.paymentToken.decimals,
        ),
      )
    : 0
}

export const useUSDPerBond = (
  bond?: Pick<Bond, 'collateralToken' | 'collateralRatio' | 'paymentToken' | 'convertibleRatio'>,
  bondAmount = 1,
) => {
  const { data: collateralTokenPrice } = useTokenPrice(bond?.collateralToken.id)
  const convertiblePerBond = bond ? getValuePerBond(bond, bond?.convertibleRatio) : 0
  const collateralPerBond = bond ? getValuePerBond(bond, bond?.collateralRatio) : 0
  const collateralValue = round(collateralPerBond * collateralTokenPrice, 3)

  return {
    collateralValue: round(collateralValue * bondAmount, 2),
    convertibleValue: round(convertiblePerBond * collateralTokenPrice * bondAmount, 3),
    convertiblePerBond,
  }
}

export const useBondExtraDetails = (
  bondId: string,
  isAuctionPage?: boolean,
): ExtraDetailsItemProps[] => {
  const { data: bond } = useBond(bondId)
  const { data: auctions } = useAuctions()

  const { collateralValue, convertiblePerBond, convertibleValue } = useUSDPerBond(bond || undefined)

  const { data: tokenBalance } = useContractRead({
    address: bondId,
    abi: BOND_ABI,
    functionName: 'totalSupply',
  })

  const navigate = useNavigate()

  const auction = auctions?.find((auction) => auction.bond.id === bond?.id)
  const link = `/auctions/${auction?.id}`

  // TODO - use this value, its value should always be close to 1 tho since its a stable
  // const { data: paymentTokenPrice } = useTokenPrice(bond?.paymentToken.id)
  const collateralPerBond = bond ? getValuePerBond(bond, bond?.collateralRatio) : 0
  const collateralizationRatio = ((collateralValue / paymentTokenPrice) * 100)?.toLocaleString()

  const strikePrice =
    convertiblePerBond > 0 ? (paymentTokenPrice / convertiblePerBond)?.toLocaleString() : 0
  const isConvertBond = bond?.type === 'convert'

  // const decimals = bond?.decimals ? bond?.decimals : 0

  const formattedTokenBalance = round(
    Number(formatUnits((tokenBalance || '0').toString(), bond?.decimals)),
    0,
  )

  const totalSupply = Math.round(Number(formatUnits(bond?.maxSupply || '0', bond?.decimals)))
  const maturityDate = dayjs(bond?.maturityDate * 1000)
    .utc()
    .format('ll')
    .toUpperCase()
  const collateralTokens = round(Number(collateralPerBond?.toLocaleString()), 2)
  const clearingPrice = round(bond?.clearingPrice?.toLocaleString(), 4)
  const collateralValueRound = round(collateralValue, 2)?.toLocaleString()
  const convertibleValueRound = round(convertibleValue, 2)?.toLocaleString()

  return [
    {
      title: 'Face value',
      value: (
        <span className="flex items-center space-x-1 ">
          <span>1</span> {bond && <TokenLink token={bond.paymentToken} withLink />}
        </span>
      ),
      tooltip: 'Amount each bond is redeemable for at maturity assuming a default does not occur.',
    },
    {
      title: 'Clearing Price',
      tooltip: 'Price per bond at the latest auction.',
      value: bond?.clearingPrice ? (
        <span className="flex items-center space-x-1">
          <span>{clearingPrice}</span>
        </span>
      ) : (
        <span className="flex items-center space-x-1">
          {bond?.auctions.length != 0 ? (
            isAuctionPage ? (
              <div>Ongoing</div>
            ) : (
              <div
                className="cursor-pointer"
                onClick={() => {
                  navigate(link)
                }}
              >
                View auction
              </div>
            )
          ) : (
            'None Sold'
          )}
        </span>
      ),
      valueText: isAuctionPage
        ? 'text-[#1677ff]'
        : bond?.clearingPrice
        ? 'text-[#1677ff]'
        : bond?.auctions.length === 0
        ? ''
        : 'text-[#09f50b]',
    },
    {
      title: 'Maturity date',
      tooltip:
        'Date each bond can be redeemed for $1 assuming no default. Convertible bonds cannot be converted after this date.',
      value: maturityDate,
      valueText: 'text-[#1677ff]',
    },
    {
      title: 'Collateral tokens',
      value: (
        <span className="flex items-center space-x-1">
          <span>{collateralTokens}</span>
          {bond && <TokenLink token={bond.collateralToken} withLink />}
        </span>
      ),
      hint: `($${collateralValueRound})`,
      tooltip:
        'Value of collateral securing each bond. If a bond is defaulted on, bondholders can exchange each bond for this amount of collateral tokens.',
    },
    {
      title: 'Convertible tokens',
      value: (
        <span className="flex items-center space-x-1">
          <span>{convertiblePerBond.toLocaleString()}</span>
          {bond && <TokenLink token={bond.collateralToken} withLink />}
        </span>
      ),
      hint: `($${convertibleValueRound})`,
      tooltip: 'Value of tokens each bond is convertible into up until the maturity date.',
      show: isConvertBond,
    },

    {
      title: 'Collateralization ratio',
      value: `${collateralizationRatio}%`,
      tooltip: 'Value of the collateral tokens divided by the face value of a bond.',
    },
    {
      title: 'Call strike price',
      tooltip: 'Price where the convertible tokens for a bond are equal to its face value.',
      value: (
        <span className="flex items-center space-x-1">
          <span>{strikePrice.toLocaleString()}</span>
          {bond && <TokenLink token={bond.paymentToken} />}
          <span>/</span>
          {bond && <TokenLink token={bond.collateralToken} />}
        </span>
      ),

      show: isConvertBond,
    },
    {
      title: 'Outstanding Bonds',
      tooltip: 'Number of unpaid bonds.',
      value: (
        <span className="flex items-center space-x-1">
          <span>{formattedTokenBalance.toLocaleString()}</span>
        </span>
      ),
    },
    {
      title: 'Issued Bonds',
      tooltip: 'Total number of bonds issued.',
      value: (
        <span className="flex items-center space-x-1">
          <span>{totalSupply.toLocaleString()}</span>
        </span>
      ),
    },
  ]
}
