import dayjs from 'dayjs'

import { Campaign } from './Banner'

const CAMPAIGNS: Campaign[] = [
  {
    key: 'ss2',
    start: 1677532131,
    end: 1678078800,
    link: '/auctions/443',
    title: 'Auction Live',
    message: "Bidding on-going for ShapeShift's second Bond",
  },
  {
    key: 'ss224',
    start: 1678078800,
    end: 1678165200,
    link: '/auctions/443',
    title: 'Auction Live',
    message: "Last 24 hours to bid on ShapeShift's second Bond",
  },
  {
    key: 'i48',
    start: 1681813509,
    end: 1681858800,
    link: '/auctions/479',
    title: 'Auction Live',
    message: 'Second to last day to bid on the Ichi Bond',
  },
  {
    key: 'i24',
    start: 1681858801,
    end: 1681945200,
    link: '/auctions/479',
    title: 'Auction Live',
    message: 'Less than a day to the Ichi Bond close',
  },
]

export const getCurrentCampaign = (): Campaign | undefined => {
  return CAMPAIGNS.find((campaign) => {
    const isBeforeEnd = dayjs.unix(campaign.end).isAfter(dayjs())
    const isAfterStart = dayjs.unix(campaign.start).isBefore(dayjs())
    return isBeforeEnd && isAfterStart
  })
}
