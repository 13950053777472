import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useFormContext } from 'react-hook-form'

import { limitOrderType } from './order/ConfigureLimitOrder'

export const BuySellControl = () => {
  const { register, setValue, watch } = useFormContext()
  const [orderType] = watch(['orderType'])

  const [searchParams] = useSearchParams()
  const orderTypeFromQuery = searchParams.get('orderType')

  const isSell = orderType === limitOrderType.Sell

  /* 
    Update the form state's orderType when the searchParmas change
    Clicking the "buy bonds" / "sell bonds" buttons in the header updates this
    However, clicking the buy / sell buttons in the form does not update params

    I could not get both working as one updating the other updates async and
    triggers another update. A potential fix may be to use previous values with
    a useRef, but I thought that was too much complexity for this. All of that
    to say, the URL isn't updated on button click, and that's OK.
  */
  useEffect(() => {
    const newOrderType = searchParams.get('orderType')
    setValue('orderType', newOrderType, {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false,
    })
  }, [searchParams, setValue])

  return (
    <div className="form-control flex w-full items-center">
      <div className="inline-flex">
        <label className={`btn ${!isSell && 'btn-active'} w-[85px]`} htmlFor="order-buy">
          Buy
        </label>
        <input
          id="order-buy"
          {...register('orderType', { value: orderTypeFromQuery || limitOrderType.Buy })}
          className={`btn sr-only ${!isSell && 'btn-active'} w-[85px]`}
          type="radio"
          value={limitOrderType.Buy}
        />
        <label className={`btn ${isSell && 'btn-active'} ml-6 w-[85px]`} htmlFor="order-sell">
          Sell
        </label>
        <input
          id="order-sell"
          {...register('orderType')}
          className={`btn sr-only ${isSell && 'btn-active'} w-[85px]`}
          type="radio"
          value={limitOrderType.Sell}
        />
      </div>
    </div>
  )
}
