import { BigNumber, constants } from 'ethers'
import React, { useState } from 'react'

import { AddressZero } from '@ethersproject/constants'
import { Token, TokenAmount } from '@josojo/honeyswap-sdk'
import { useContractRead, useToken } from 'wagmi'

import { ActionButton } from '@/components/auction/Claimer'
import BOND_ABI from '@/constants/abis/bond.json'
import { useActiveWeb3React } from '@/hooks'
import { ApprovalState, useApproveCallback } from '@/hooks/useApproveCallback'

export const ApproveButton = ({
  amountToCheck,
  children,
  contract,
  hasInsufficientAllowanceComponent,
  onAllowanceChecked,
  owner,
  smallText,
  spender,
}: {
  contract: string
  owner?: string
  spender: string
  amountToCheck: string | BigNumber
  hasInsufficientAllowanceComponent?: React.ReactNode
  onAllowanceChecked?: (allowance: boolean) => void
  children?: React.ReactNode
  smallText?: boolean
}) => {
  const { account, chainId } = useActiveWeb3React()
  if (!owner) {
    owner = account
  }
  const [waitingForApproval, setWaitingForApproval] = useState(false)
  console.log({ amountToCheck, contract, owner, spender })
  const { data: tokenAllowance, isLoading: isAllowanceLoading } = useContractRead({
    address: contract || AddressZero,
    abi: BOND_ABI,
    functionName: 'allowance',
    args: [owner, spender],
  })
  const { data, isLoading: isTokenLoading } = useToken({
    address: contract,
  })
  const token = isTokenLoading
    ? null
    : new Token(chainId, contract, data?.decimals, data?.symbol, data?.name)

  const [approval, approveCallback] = useApproveCallback(
    isTokenLoading ? null : new TokenAmount(token, BigInt(constants.MaxUint256.toString())),
    spender,
    chainId,
  )

  if (isAllowanceLoading || isTokenLoading || !(tokenAllowance instanceof BigNumber)) {
    return null
  }

  onAllowanceChecked && onAllowanceChecked(approval === ApprovalState.APPROVED)

  const approveToken = async () => {
    try {
      setWaitingForApproval(true)
      await approveCallback()
    } catch (e) {
      console.log(e?.message || e)
    } finally {
      setWaitingForApproval(false)
    }
  }
  return (
    <>
      {approval === ApprovalState.APPROVED ? (
        children
      ) : (
        <>
          {hasInsufficientAllowanceComponent}
          <ActionButton
            className={`border-none bg-transparent text-[#1c701c] hover:text-white hover:decoration-transparent ${
              waitingForApproval || approval === ApprovalState.PENDING ? 'loading' : ''
            }`}
            color="purple"
            onClick={approveToken}
          >
            {smallText ? 'Approve' : `Approve ${data.symbol}`}
          </ActionButton>
        </>
      )}
    </>
  )
}
